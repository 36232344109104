import React, {Component, Fragment} from 'react';
import FullScreenLoader from "../Common/FullScreenLoader";
import {Redirect} from "react-router";
import img from "./../../Assets/Img/payment_success.svg"
import {Link} from "react-router-dom";
import {ProductOrderBody, ProductOrderURL} from "../../APIServices/ProductOrderRequest";
import axios from "axios";
import SessionHelper from "../../Helper/SessionHelper";
import {TempVerifyURL} from "../../APIServices/TempVerifyRequest";
import {OrderPlaceInProgress, OrderPlaceSuccess, PaymentVerify} from "../../Helper/ToastMassageHelper";

class PaymentSuccess extends Component {

    constructor() {
        super();
        this.state={
            isLoading:"d-none",
            Redirect:false
        }
    }

    componentDidMount() {
        this.VerifyTemp();
    }

    VerifyTemp=()=>{
        OrderPlaceInProgress();
        this.setState({isLoading:""})
        axios.get(TempVerifyURL(this.props.trx))
            .then((res)=>{
                if(res.status===200 && res.data===1){
                    PaymentVerify();
                    this.ConfirmOrderSuccess();
                }
                else {
                    this.setState({isLoading:"d-none"})
                }
            })
            .catch((err)=>{
                this.setState({isLoading:"d-none"})
            })
    }

    ConfirmOrderSuccess=()=>{
        let URL=ProductOrderURL();
        let Body=ProductOrderBody()
        axios.post(URL,Body).then((res)=>{
            if(res.status===200 && res.data!==0){
                this.setState({isLoading:"d-none"})
                SessionHelper.clearProductOrderRequestInput();
                OrderPlaceSuccess();
            }
            else{
                this.setState({isLoading:"d-none"})
            }
        }).catch((err)=>{
            this.setState({isLoading:"d-none"})
        })
    }

    pageRedirect=()=>{
        if(this.state.Redirect===true){
            return(
                <Redirect to="/otp"/>
            )
        }
    }

    render() {
        return (
            <Fragment>
                <div className="container ">
                    <div className="row d-flex animated zoomIn justify-content-center">
                        <div className="col-md-10 my-4 col-lg-10 col-12 col-sm-12">
                            <div className="container shadow-sm p-2 bg-white">
                                <div className="row d-flex justify-content-center">
                                    <div className="col-md-4 text-center login-form col-lg-4 col-sm-8 col-12  ">
                                        <img className="w-50" src={img}/>
                                        <Link to="/orderHistory" className="btn my-3 btn-dark">ক্রয়ের ইতিহাস চেক করুন</Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.pageRedirect()}
                <FullScreenLoader isLoading={this.state.isLoading}/>
                <br/><br/><br/><br/><br/><br/><br/>
            </Fragment>
        );
    }
}
export default PaymentSuccess;