// Reviewed
import React, {Component,Fragment} from 'react';
import {Navbar, Nav, NavDropdown, Form} from "react-bootstrap";
import { BiBell } from "react-icons/bi";
import { CgLogIn, CgLogOut} from "react-icons/cg";
import { IoBagHandleSharp } from "react-icons/io5";
import {Link, NavLink} from "react-router-dom";
import {BsSearch} from 'react-icons/bs'
import {RetrieveCategories, StoreCategories} from "../../Helper/LocalForgeHelper";
import axios from "axios";
import {SelectCategoryURL} from "../../APIServices/SelectCategoryRequest";
import SessionHelper from "../../Helper/SessionHelper";
import {Redirect} from "react-router";
import {CartListCountURL} from "../../APIServices/CartListCount";
import {RequiredSearchKey} from "../../Helper/ToastMassageHelper";
import logo from "./../../Assets/Img/logo.png"
import {FaEnvelope, FaFacebook, FaMobile} from "react-icons/all";
import {FaYoutube} from "react-icons/fa";
class TopNavigation extends Component {

    constructor() {
        super();
        this.state={
            DataList:null,
            Redirect:false,
            cartItem:0,
            searchKey:"",
            searchRedirect:false,
        }
    }
    componentDidMount() {
        this.SelectCategory()
        this.cartItemCount();
    }
    SelectCategory=()=>{
        RetrieveCategories((StoreData)=>{
            if(StoreData!==null){
                this.setState({DataList:StoreData})
            }
            else {
                axios.get(SelectCategoryURL()).then((res)=>{
                    this.setState({DataList:res.data})
                    StoreCategories(res.data)
                })
            }
        })
    }
    logOut=()=>{
      SessionHelper.ClearAllSession();
      this.setState({Redirect:true})
    }
    pageRedirect=()=>{
        if(this.state.Redirect===true){
            return (
                <Redirect to="/"/>
            )
        }
    }
    cartItemCount(){
         axios.get(CartListCountURL()).then((res)=>{
             this.setState({cartItem:res.data})
         })
    }
    search=()=>{
        let searchKey=this.state.searchKey;
        if(searchKey.length===0){
            RequiredSearchKey();
        }
        else{
            this.setState({searchRedirect:true});
        }
    }

    searchRedirectPage=()=>{
        if(this.state.searchRedirect===true){
            return (
                <Redirect to={"/search/"+this.state.searchKey}/>
            )
        }
    }

    render() {
        let CategoryList=this.state.DataList;
        let CategoryListView= <Nav.Link> </Nav.Link>
        if(CategoryList!==null){
            CategoryListView=CategoryList.map((list,i)=>{
                return(
                    <Nav.Link> <NavLink exact className="nav-item mx-1" to={"/ProductList/"+list['cat_code']}>{list['cat_name']}</NavLink> </Nav.Link>
                )
            })
        }
        let LoginStatus=SessionHelper.getLogin();
        if(LoginStatus==="YES"){
            return (
                <Fragment>
                    <div className="container-fluid nav-top-top m-0  desktop">
                        <div className="row">
                            <div  className="col">
                                <div className="input-group">
                                    <Link to="/Contact" className="btn btn-sm nav-top-btn"><FaEnvelope/>  যোগাযোগ</Link>
                                    <a href="" target="_blank"  className="btn btn-sm nav-top-btn"><FaFacebook/>  ফেছবুক</a>
                                    <a href="" target="_blank"  className="btn btn-sm nav-top-btn"><FaYoutube/>  ইউটিউব</a>
                                    <a href="tel:01987210026"  className="btn btn-sm nav-top-btn"><FaMobile/>  হটলাইন 01987210026</a>

                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="container-fluid  bg-white m-0 desktop">
                        <div className="row">
                            <div className="col-lg-2 col-md-2 col-sm-6 p-1 col-4">
                                <NavLink exact to="/"><img className="nav-logo" src={logo} alt=""/></NavLink>
                            </div>
                            <div className="col-lg-5 col-md-5 col-sm-12 col-12 px-1 py-4">
                                <div className="input-group ">
                                    <input onChange={e=>{this.setState({searchKey:e.target.value})}} type="text" className="form-control form-search-control" placeholder="আপনি কি খুঁজছেন?"/>
                                    <div className="input-group-append">
                                        <button onClick={this.search} className="btn btn-success"> <BsSearch/> </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5 col-sm-12 px-1 col-12 py-3 ">
                                <Nav className="float-right">
                                    <Nav.Link> <NavLink exact className="btn theme-btn" to="/CartList"><IoBagHandleSharp/> {this.state.cartItem} আইটেম</NavLink> </Nav.Link>
                                    <Nav.Link> <NavLink exact className="btn theme-btn" to="/NotificationList"><BiBell/> বার্তা</NavLink> </Nav.Link>
                                    <Nav.Link> <a  className="btn theme-btn " onClick={this.logOut}><CgLogOut />লগ-আউট</a> </Nav.Link>
                                </Nav>
                            </div>
                        </div>
                    </div>
                    <header className="sticky-top desktop">
                        <Navbar className="desktop" variant="light" >
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto">
                                <Nav.Link> <NavLink exact className="nav-item mx-1" to="/">হোম</NavLink> </Nav.Link>
                                {CategoryListView}
                            </Nav>
                            <Form>
                                <div className="mr-3">
                                    <NavDropdown title="অ্যাকাউন্ট" id="basic-nav-dropdown">
                                        <NavDropdown.Item><NavLink exact className="nav-item-top btn m-0 p-1 " to="/orderHistory">অর্ডার সমুহ</NavLink></NavDropdown.Item>
                                        <NavDropdown.Item><NavLink exact className="nav-item-top btn m-0 p-1 " to="/fav">পছন্দের তালিকা</NavLink></NavDropdown.Item>
                                    </NavDropdown>
                                </div>
                            </Form>
                        </Navbar.Collapse>
                    </Navbar>
                    </header>
                    {this.pageRedirect()}
                    {this.searchRedirectPage()}
                </Fragment>
            );
        }
        else {
            return (
                <Fragment>
                    <div className="container-fluid  nav-top-top m-0  desktop">
                        <div className="row">
                            <div  className="col">
                                <div className="input-group">
                                    <Link to="/Contact" className="btn btn-sm nav-top-btn"><FaEnvelope/>  যোগাযোগ</Link>
                                    <a href="" target="_blank"  className="btn btn-sm nav-top-btn"><FaFacebook/>  ফেছবুক</a>
                                    <a href="" target="_blank"  className="btn btn-sm nav-top-btn"><FaYoutube/>  ইউটিউব</a>
                                    <a href="tel:01987210026"  className="btn btn-sm nav-top-btn"><FaMobile/>  হটলাইন 01987210026</a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="container-fluid bg-white m-0 desktop">
                        <div className="row">
                            <div className="col-lg-2 col-md-2 col-sm-6 p-1 col-4">
                                <NavLink exact to="/"><img className="nav-logo" src={logo} alt=""/></NavLink>
                            </div>
                            <div className="col-lg-5 col-md-5 col-sm-12 col-12 px-1 py-4">
                                <div className="input-group ">
                                    <input onChange={e=>{this.setState({searchKey:e.target.value})}}  type="text" className="form-search-control form-control" placeholder="আপনি কি খুঁজছেন?"/>
                                    <div className="input-group-append">
                                        <button onClick={this.search} className="btn btn-success"> <BsSearch/> </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5 col-sm-12 px-1 col-12 py-3 ">
                                <Nav className="float-right">
                                    <Nav.Link> <NavLink exact className="btn theme-btn" to="/Login"><CgLogIn/> লগইন</NavLink> </Nav.Link>
                                    <Nav.Link> <NavLink exact className="btn theme-btn" to="/NotificationList"><BiBell/> বার্তা</NavLink> </Nav.Link>
                                    <Nav.Link> <NavLink exact className="btn theme-btn" to="/CartList"><IoBagHandleSharp/> {this.state.cartItem} আইটেম</NavLink> </Nav.Link>
                                </Nav>
                            </div>
                        </div>
                    </div>
                    <header className="sticky-top desktop">
                    <Navbar variant="light" >
                            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="ml-5 p-0">
                                    <Nav.Link> <NavLink exact className="nav-item mx-1" to="/">হোম</NavLink> </Nav.Link>
                                    {CategoryListView}
                                </Nav>
                            </Navbar.Collapse>
                    </Navbar>
                    </header>
                    {this.pageRedirect()}
                    {this.searchRedirectPage()}
                </Fragment>
            );
        }
    }
}
export default TopNavigation;