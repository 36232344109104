class ValidationHelper{

    static EmailRegx = /\S+@\S+\.\S+/;
    static MobileRegx = /(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/;
    static OnlyNumberRegx = /^\d+$/;
    static OnlyLaterRegx = /^[A-Za-z\'\s\.\,\-\']+$/;
    static NIDRegx = /^(\d{10}|\d{13}|\d{17})$/
    static BrithCirtificateRegx = /^(\d{17})$/;
    static RepeatedRegx = /(.)\1{3,}/;
    static validFileExtensions = [ "jpg",'JPG',"jpeg",'JPEG',"PNG", "png"];
    static validFileExtensionsETIN = [ "jpg",'JPG',"jpeg",'JPEG',"PNG", "png"];


}
export default ValidationHelper