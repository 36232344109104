//Completed By Rabbil
import React, {Component} from 'react';
import loader from "../../Assets/Img/loader.svg"
class FullScreenLoader extends Component {
    render() {
        return (
            <div className={this.props.isLoading+ " ProcessingDiv"}>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-12 center-screen p-5">
                        <img className="loader-size" alt="loader" src={loader} />
                    </div>
                </div>
            </div>
        );
    }
}
export default FullScreenLoader;