// Completed By Rabbil Hasan
// 14-05-2014 5:43AM
import React, {Component} from 'react';
import {ListGroup} from "react-bootstrap";
class NotificationPlaceholder extends Component {
    render() {
        return (
            <>
            <ListGroup.Item>
                                <div className="ph-item">
                                    <div className="ph-col-12">
                                        <div className="ph-row">
                                            <div className="ph-col-8 small"/>
                                            <div className="ph-col-6 small"/>
                                            <div className="ph-col-12 small"/>
                                        </div>
                                    </div>
                                </div>
                            </ListGroup.Item>
            <ListGroup.Item>
                                <div className="ph-item">
                                    <div className="ph-col-12">
                                        <div className="ph-row">
                                            <div className="ph-col-8 small"/>
                                            <div className="ph-col-6 small"/>
                                            <div className="ph-col-12 small"/>
                                        </div>
                                    </div>
                                </div>
                            </ListGroup.Item>
            <ListGroup.Item>
                                <div className="ph-item">
                                    <div className="ph-col-12">
                                        <div className="ph-row">
                                            <div className="ph-col-8 small"/>
                                            <div className="ph-col-6 small"/>
                                            <div className="ph-col-12 small"/>
                                        </div>
                                    </div>
                                </div>
                            </ListGroup.Item>
            <ListGroup.Item>
                                <div className="ph-item">
                                    <div className="ph-col-12">
                                        <div className="ph-row">
                                            <div className="ph-col-8 small"/>
                                            <div className="ph-col-6 small"/>
                                            <div className="ph-col-12 small"/>
                                        </div>
                                    </div>
                                </div>
                            </ListGroup.Item>
            <ListGroup.Item>
                                <div className="ph-item">
                                    <div className="ph-col-12">
                                        <div className="ph-row">
                                            <div className="ph-col-8 small"/>
                                            <div className="ph-col-6 small"/>
                                            <div className="ph-col-12 small"/>
                                        </div>
                                    </div>
                                </div>
                            </ListGroup.Item>
            <ListGroup.Item>
                                <div className="ph-item">
                                    <div className="ph-col-12">
                                        <div className="ph-row">
                                            <div className="ph-col-8 small"/>
                                            <div className="ph-col-6 small"/>
                                            <div className="ph-col-12 small"/>
                                        </div>
                                    </div>
                                </div>
                            </ListGroup.Item>
            <ListGroup.Item>
                                <div className="ph-item">
                                    <div className="ph-col-12">
                                        <div className="ph-row">
                                            <div className="ph-col-8 small"/>
                                            <div className="ph-col-6 small"/>
                                            <div className="ph-col-12 small"/>
                                        </div>
                                    </div>
                                </div>
                            </ListGroup.Item>
            <ListGroup.Item>
                                <div className="ph-item">
                                    <div className="ph-col-12">
                                        <div className="ph-row">
                                            <div className="ph-col-8 small"/>
                                            <div className="ph-col-6 small"/>
                                            <div className="ph-col-12 small"/>
                                        </div>
                                    </div>
                                </div>
                            </ListGroup.Item>
            </>
        );
    }
}
export default NotificationPlaceholder;