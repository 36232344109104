// Reviewed
import React, {Component,Fragment} from 'react';
import axios from "axios";
import {SelectReviewURL} from "../../APIServices/SelectReviewRequest";
import {AiFillStar, AiOutlineStar} from "react-icons/ai";
import ReviewListPlaceholder from "./ReviewListPlaceholder";
import NoReviewFound from "../Common/NoReviewFound";

class ReviewList extends Component {

    constructor() {
        super();
        this.state={
            DataList:null,
            nodata:false,
        }
    }

    componentDidMount() {
        this.SelectReview();
    }

    SelectReview=()=>{
        axios.get(SelectReviewURL(this.props.code))
            .then((res)=>{
                if(res.status===200 && res.data.length>0){
                    this.setState({DataList:res.data})
                }
                else if(res.data.length===0){
                    this.setState({nodata:true})
                }
                else{
                    this.setState({nodata:true})
                }
            }).catch((err)=>{
            this.setState({nodata:true})
        })
    }


    render() {

        let DataList=this.state.DataList;
        let DataView=<ReviewListPlaceholder/>;
        let OneStarView=<span>
                    <AiFillStar className="text-success"/>
                    <AiOutlineStar className="text-success"/>
                    <AiOutlineStar className="text-success"/>
                    <AiOutlineStar className="text-success"/>
                    <AiOutlineStar className="text-success"/>
                    </span>
        let TwoStarView=<span>
                    <AiFillStar className="text-success"/>
                    <AiFillStar className="text-success"/>
                    <AiOutlineStar className="text-success"/>
                    <AiOutlineStar className="text-success"/>
                    <AiOutlineStar className="text-success"/>
                    </span>
        let ThreeStarView=<span>
                    <AiFillStar className="text-success"/>
                    <AiFillStar className="text-success"/>
                    <AiFillStar className="text-success"/>
                    <AiOutlineStar className="text-success"/>
                    <AiOutlineStar className="text-success"/>
                    </span>
        let FourStarView=<span>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
        </span>
        let FiveStarView=<span>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
        </span>

        if(DataList!==null) {
            DataView = DataList.map((list, i) => {
                let StarView;
                if (list['reviewer_rating'] === "1") {
                    StarView = OneStarView;
                } else if (list['reviewer_rating'] === "2") {
                    StarView = TwoStarView;
                } else if (list['reviewer_rating'] === "3") {
                    StarView = ThreeStarView;
                } else if (list['reviewer_rating'] === "4") {
                    StarView = FourStarView;
                } else if (list['reviewer_rating'] === "5") {
                    StarView = FiveStarView;
                }
                return (
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <h3 className="review-title m-0 p-0">{list['reviewer_name']}</h3>
                        <p className="m-0 p-0">{StarView}</p>
                        <h3 className="review-des m-0 p-0">{list['reviewer_comments']}</h3>
                        <hr/>
                    </div>
                )
            })

        }

        else if(this.state.nodata===true){
            DataView=<NoReviewFound/>
        }

        return (
                <Fragment>
                    {DataView}
                </Fragment>
        );


    }
}

export default ReviewList;