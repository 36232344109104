// Completed By Rabbil Hasan
// 14-05-2014 5:43AM
import React, {Component,Fragment} from 'react';
import {ListGroup} from "react-bootstrap";
import axios from "axios";
import {NotificationURL} from "../../APIServices/NotificationRequest";
import NotificationPlaceholder from "./NotificationPlaceholder";
import NoDataFound from "../Common/NoDataFound";
class Notification extends Component {
    constructor() {
        super();
        this.state={
            DataList:null,
            nodata:false,
        }
    }
    componentDidMount() {
        this.SelectNotification();
    }
    SelectNotification=()=>{
        axios.get(NotificationURL())
            .then((res)=>{
                if(res.status===200 && res.data.length>0){
                    this.setState({DataList:res.data})
                }
                else if(res.data.length===0){
                    this.setState({nodata:true})
                }
                else{
                    this.setState({nodata:true})
                }
            }).catch((err)=>{
            this.setState({nodata:true})
        })
    }
    render() {
        let DataList=this.state.DataList;
        let DataView=<NotificationPlaceholder/>
        if(DataList!==null){
            DataView=DataList.map((list,i)=>{
                return(
                    <ListGroup.Item className="animated fadeIn">
                        <h1 className="notification-title">মোবাইল অ্যাপে মিলছে রাজশাহীর আম রাজশাহীর আম</h1>
                        <h6 className="notification-date">Date: 1/1/2021</h6>
                        <h1 className="notification-des">মোবাইল অ্যাপে মিলছে রাজশাহীর আম রাজশাহীর আম মোবাইল অ্যাপে মিলছে রাজশাহীর আম রাজশাহীর আম মোবাইল অ্যাপে মিলছে রাজশাহীর আম রাজশাহীর আম মোবাইল অ্যাপে মিলছে রাজশাহীর আম রাজশাহীর আম</h1>
                    </ListGroup.Item>
                )
            })
        }
        else if(this.state.nodata===true){
            DataView=<NoDataFound/>
        }


        return (
            <Fragment>
                <div className="container shadow-sm bg-white my-4 ">
                    <div className="row">
                        <div className="col-lg-12 py-4 col-md-121 col-sm-12 col-12">
                            <ListGroup variant="flush">
                                {DataView}
                            </ListGroup>
                        </div>
                    </div>
                </div>
        </Fragment>
        );
    }
}
export default Notification;