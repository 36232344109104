// Completed By Rabbil Hasan
// 14-05-2014 5:43AM
import React, {Component,Fragment} from 'react';
import axios from "axios";
import {SiteInfoURL} from "../../APIServices/SiteInfoRequest";
import TermsDescriptionPlaceholder from "../TermsDescription/TermsDescriptionPlaceholder";
import ReactHtmlParser from "react-html-parser";
class TermsDescription extends Component {
    constructor() {
        super();
        this.state={
            des:null
        }
    }
    componentDidMount() {
        axios.get(SiteInfoURL()).then((res)=>{
            this.setState({ des:(res.data)[0]['terms']})
        })
    }
    render() {
        let des=this.state.des;
        let desView=<TermsDescriptionPlaceholder/>
        if(des!==null){
            desView= <div className="animated fadeIn">{ReactHtmlParser(des)}</div>;
        }
        return (
            <Fragment>
                <div className="container shadow-sm bg-white my-4 ">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-4">
                            {desView}
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default TermsDescription;