//Completed By Rabbil
import React, {Component,Fragment} from 'react';
import {Link} from "react-router-dom";
import {FaFacebookF,FaYoutube} from "react-icons/fa"
import { RetrieveFooter, StoreFooter} from "../../Helper/LocalForgeHelper";
import axios from "axios";
import {SiteInfoURL} from "../../APIServices/SiteInfoRequest";
import ReactHtmlParser from "react-html-parser";
class Footer extends Component {
    constructor() {
        super();
        this.state={
            about:"",
            address:"",
            android:"",
            ios:"",
            facebook:"",
            youtube:"",
        }
    }
    componentDidMount() {
        this.SelectFooter()
    }
    SelectFooter=()=>{
        RetrieveFooter((StoreData)=>{
            if(StoreData!==null){
                this.setState({
                    about:(StoreData)[0]['about_company'],
                    address:(StoreData)[0]['address'],
                    android:(StoreData)[0]['android_app_link'],
                    ios:(StoreData)[0]['ios_app_link'],
                    facebook:(StoreData)[0]['facebook_link'],
                    youtube:(StoreData)[0]['instagram_link']
                })
            }
            else {
                axios.get(SiteInfoURL()).then((res)=>{
                    this.setState({
                        about:(res.data)[0]['about_company'],
                        address:(res.data)[0]['address'],
                        android:(res.data)[0]['android_app_link'],
                        ios:(res.data)[0]['ios_app_link'],
                        facebook:(res.data)[0]['facebook_link'],
                        youtube:(res.data)[0]['instagram_link']
                    })
                    StoreFooter(res.data)
                })
            }
        })
    }
    render() {
        return (
            <Fragment>
                <br/><br/><br/><br/><br/><br/><br/>
                <footer className="site-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 col-md-3">
                                <h1 className="footer-title">আমাদের সম্পর্কে</h1>
                                <p className="footer-des">{ReactHtmlParser(this.state.about)}</p>
                            </div>
                            <div className="col-xs-6 col-md-3">
                                <h1 className="footer-title">আমাদের ঠিকানা</h1>
                                {ReactHtmlParser(this.state.address)}
                            </div>
                            <div className="col-xs-6 col-md-3">
                                <h1 className="footer-title">ইনফরমেশন</h1>
                                <ul className="footer-links">
                                    <li><Link className="FooterInfoLinkText" to="/HowPurchase">কিভাবে কিনবেন</Link></li>
                                    <li><Link className="FooterInfoLinkText" to="/About">আমাদের সম্পর্কে</Link></li>
                                    <li><Link className="FooterInfoLinkText" to="/Contact">যোগাযোগ</Link></li>
                                    <li><Link className="FooterInfoLinkText" to="/Privacy">প্রাইভেসি পলিসি</Link></li>
                                    <li><Link className="FooterInfoLinkText" to="/Terms">শর্ত সমুহ</Link></li>
                                    <li><Link className="FooterInfoLinkText" to="/Refund">ফেরত নীতি</Link></li>
                                </ul>
                            </div>

                            <div className="col-xs-6 col-md-3">
                                <h1 className="footer-title">ডাউনলোড অ্যাপ</h1>
                                <a href={this.state.android} target="_blank"><img className="Download-app mb-1 footer-links" src="../images/android.svg" alt=""/></a>
                                <a href={this.state.ios} target="_blank"><img className="Download-app mb-1 footer-links" src="../images/apple.svg" alt=""/></a>
                            </div>
                        </div>
                        <hr/>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-6 col-xs-12">
                                <p className="copyright-text "><b>সর্বস্বত্ব সংরক্ষিত ২০২১-২০২২
                                    <a  className="FooterInfoLinkText" href="/"> আম বাগান</a></b>
                                </p>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <ul className="social-icons">
                                    <li><a className="facebook" href={this.state.facebook} target="_blank"><FaFacebookF/></a></li>
                                    <li><a className="youtube" href={this.state.youtube} target="_blank"><FaYoutube/></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
            </Fragment>
        );
    }
}
export default Footer;