// Reviewed
import React, {Component,Fragment} from 'react';
import nodata from './../../Assets/Img/nodata.svg';
class NoDataFound extends Component {
    render() {
        return (
            <Fragment>
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-4 text-center nodata-div col-md-4 col-sm-6 col-6">
                            <img alt="nodata" className="nodata-img" src={nodata}/>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default NoDataFound;