// Reviewed
import React, {Component,Fragment} from 'react';
import axios from "axios";
import {SelectCategoryURL} from "../../APIServices/SelectCategoryRequest";
import {NavLink} from "react-router-dom";
import PopularCategoriesPlaceholder from "./PopularCategoriesPlaceholder";
class PopularCategories extends Component {
    constructor() {
        super();
        this.state={
            DataList:null
        }
    }
    componentDidMount() {
        this.SelectCategory()
    }
    SelectCategory=()=>{
        axios.get(SelectCategoryURL()).then((res)=>{
            this.setState({DataList:res.data})
        })
    }
    render() {
        let CategoryList=this.state.DataList;
        let CategoryListView=<PopularCategoriesPlaceholder/>;
        if(CategoryList!==null){
            CategoryListView=CategoryList.map((list,i)=>{
                return(
                        <div className="col-lg-2 animated zoomIn col-md-3 col-sm-3 col-4 p-1">
                            <NavLink exact  to={"/ProductList/"+list['cat_code']}>
                                <div className="card h-100 w-100 text-center">
                                    <div className="card-body image-box">
                                        <img alt="" className="w-100" src={list['cat_image']} />
                                        <h5 className="card-title mt-2">{list['cat_name']}</h5>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                )
            })
        }
        return (
            <Fragment>
                <div className="container-fluid mt-4 mb-4">
                    <h4 className="section-title text-center"> - জনপ্রিয় ক্যাটেগরি - </h4>
                    <div className="row">
                        {CategoryListView}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default PopularCategories;