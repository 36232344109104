import React, {Component,Fragment} from 'react';
import TopNavigation from "../../Components/Common/TopNavigation";
import BlogDetails from "../../Components/BlogDetails/BlogDetails";
import Footer from "../../Components/Common/Footer";
import BlogComments from "../../Components/BlogDetails/BlogComments";
import TopNavigationMobile from "../../Components/Common/TopNavigationMobile";
import FooterMobile from "../../Components/Common/FooterMobile";

class BlogDetailsPage extends Component {

    componentDidMount() {
        window.scroll(0,0)
    }


    render() {
        return (
            <Fragment>
                <TopNavigation/>
                <div className="mobile nav-mobile-top">
                    <TopNavigationMobile/>
                </div>
                <BlogDetails/>
                <BlogComments/>
                <div className="desktop">
                    <Footer/>
                </div>
                <div className="mobile">
                    <FooterMobile/>
                </div>
            </Fragment>
        );
    }
}

export default BlogDetailsPage;