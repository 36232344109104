// Reviewed
import React, {Component,Fragment} from 'react';
import axios from "axios";
import {CheckOutDetailsURL} from "../../APIServices/checkOutDetailsRequest";
import {
    CouponMinimunPrice, CouponSuccess,
    emptyCoupon,
    invalidCoupon,
    requestFail,
    RequiredPaymentMethod
} from "../../Helper/ToastMassageHelper";
import SessionHelper from "../../Helper/SessionHelper";
import FullScreenLoader from "../Common/FullScreenLoader";
import {Redirect} from "react-router";
import coupon from "../../Assets/Img/coupon.png"
import {ProductOrderTemp,ProductOrderTempBody} from "../../APIServices/ProductOrderRequest";
import {CouponRequestURL} from "../../APIServices/couponRequest";
class CheckOut extends Component {
    constructor() {
        super();
        this.state={
            DataList:null,
            totalProductWeight:"",
            totalProductPrice:"",
            totalPackagingPrice:"",
            totalDeliveryCharge:"",
            totalPayment:"",
            paymentMethod:"",
            isLoading:"d-none",
            RedirectToCart:false,
            CouponCode:"",
            cupon_name:"",
            minimun_order_condition:"",
            cupon_amount:"",
            cupon_status:"",
            CouponDiv:"d-none",
            CouponInputDiv:"",
            ImageDiv:"d-none",
            CouponUsed:false,

        }
    }


    RedirectToCartPage=()=>{
        if(this.state.RedirectToCart===true){
            return (
                <Redirect to="/CartList"/>
            )
        }
    }



    componentDidMount() {
        this.getCheckOutDetails();
    }
    getCheckOutDetails=()=>{
        this.setState({isLoading:""})
        axios.get(CheckOutDetailsURL()).then((res)=>{
            if(res.status===200){
                this.setState({isLoading:"d-none"})
                this.setState({DataList:res.data})
                let totalProductPrice=res.data['TotalProductPrice'];
                let totalPackagingPrice=res.data['TotalPackingCharge'];
                let totalShippingCharge=parseFloat(res.data['ShippingChargeWithoutMango'])+parseFloat(res.data['MangoShippingCharge'])
                let TotalDue=parseFloat(totalProductPrice)+parseFloat(totalPackagingPrice)+totalShippingCharge
                let totalWeightWithoutMango=res.data['TotalQuantityWithoutMangoRound'];
                let totalWeightMango=res.data['TotalQuantityOnlyMango'];
                let totalWeight=parseFloat(totalWeightWithoutMango)+parseFloat(totalWeightMango)
                this.setState({totalProductPrice:totalProductPrice})
                this.setState({totalPackagingPrice:totalPackagingPrice})
                this.setState({totalDeliveryCharge:totalShippingCharge})
                this.setState({totalPayment:TotalDue})
                this.setState({totalProductWeight:totalWeight})
            }
            else {
                this.setState({isLoading:"d-none"})
                requestFail();
                this.setState({RedirectToCart:true})
            }
        }).catch((err)=>{
            this.setState({isLoading:"d-none"})
            requestFail();
            this.setState({RedirectToCart:true})
        })
    }
    next=()=>{
        let paymentMethod=this.state.paymentMethod
        if(paymentMethod.length===0){
            RequiredPaymentMethod();
        }
        else if(paymentMethod==="SSL"){
            this.setState({isLoading:""})


            //Generate Invoice Number
            let d = new Date();
            let n = d.getTime();
            let InvoiceNo="Rajshahi-Mango-"+n
            let city=SessionHelper.getCity();
            let location=SessionHelper.getLocation();
            let Email=SessionHelper.getEmail();
            let UserMobile=SessionHelper.getMobile();
            let ReceiverMobile=SessionHelper.getReceiverMobile();
            let ReceiverName=SessionHelper.getReceiverName();
            let ShippingPoint=SessionHelper.getShippingPoint();
            let ReceiverAddress=SessionHelper.getReceiverAddress();

            let totalProductWeight=this.state.totalProductWeight;
            let totalProductPrice=this.state.totalProductPrice;
            let totalPackagingPrice=this.state.totalPackagingPrice;
            let totalDeliveryCharge=this.state.totalDeliveryCharge;
            let totalPayment=this.state.totalPayment;
            let paymentStatus="Processing";
            let cupon_name= this.state.cupon_name;
            let cupon_amount=this.state.cupon_amount;

            let URL=ProductOrderTemp();
            let Body=ProductOrderTempBody(
                cupon_name,
                cupon_amount,
                InvoiceNo,
                UserMobile,
                totalProductWeight,
                totalProductPrice,
                totalPackagingPrice,
                totalDeliveryCharge,
                totalPayment,
                paymentStatus,
                city,
                location,
                ShippingPoint,
                ReceiverName,
                ReceiverMobile,
                ReceiverAddress
            )
            axios.post(URL,Body).then((res)=>{
                if(res.status===200 && res.data!==0){
                    window.location.href=process.env.REACT_APP_PAYMENT+res.data;
                }
                else{
                    requestFail();
                    this.setState({isLoading:"d-none"})
                }
            }).catch((err)=>{
                requestFail();
                this.setState({isLoading:"d-none"})
            })
        }
    }
    applyCoupon=()=>{
        let Coupon= this.state.CouponCode;
        if(this.state.CouponUsed===false){
            if(Coupon.length===0){
                emptyCoupon();
            }
            else {
                this.setState({isLoading:""})
                axios.get(CouponRequestURL(this.state.totalProductPrice,Coupon)).then((res)=>{
                    this.setState({isLoading:"d-none"})
                    if(res.status===200){
                        if(res.data===0){
                            invalidCoupon();
                        }
                        else {
                            let minimun_order_condition= (res.data)[0]['minimun_order_condition'];
                            let cupon_amount=(res.data)[0]['cupon_amount'];
                            let cupon_status=(res.data)[0]['cupon_status'];
                            let cupon_name=(res.data)[0]['cupon_name'];

                            if(minimun_order_condition>this.state.totalProductPrice){
                                CouponMinimunPrice(minimun_order_condition);
                            }
                            else {
                                let newPrice= parseFloat(this.state.totalPayment)-(parseFloat(cupon_amount));
                                this.setState({totalPayment:newPrice});
                                CouponSuccess(cupon_amount);
                                this.setState({CouponDiv:""});
                                this.setState({cupon_name:cupon_name, minimun_order_condition:minimun_order_condition, cupon_amount:cupon_amount, cupon_status:cupon_status})
                                this.setState({
                                    CouponInputDiv:"d-none",
                                    ImageDiv:"",
                                    CouponUsed:true,
                                })
                            }
                        }
                    }
                    else {
                        invalidCoupon();
                    }
                }).catch((err)=>{
                    this.setState({isLoading:"d-none"})
                    requestFail();
                })
            }
        }
        else{
            alert("Bad Boy")
        }



    }



    render() {
        return (
            <Fragment>
                <div className="container ">
                    <div className="row d-flex animated zoomIn justify-content-center">
                        <div className="col-md-10 my-4 col-lg-10 col-12 col-sm-12">
                            <div className="container shadow-sm p-2 bg-white">
                                <div className="row">
                                    <div className="col-md-6  col-lg-6 col-sm-12 col-12  ">
                                        <div className="login-form">
                                            <h5 className="checkout-item">মোট পণ্যের দামঃ {this.state.totalProductPrice}/- টাকা </h5>
                                            <h5 className="checkout-item">মোট প্যাকেজিং চার্জঃ {this.state.totalPackagingPrice}/- টাকা</h5>
                                            <h5 className="checkout-item">ডেলিভেরি চার্জঃ {this.state.totalDeliveryCharge}/- টাকা</h5>
                                            <hr className="bg-light"/>
                                            <h5 className={this.state.CouponDiv+"  checkout-item-total"}>কুপন থেকে ডিস্কাউন্টঃ {this.state.cupon_amount}/- টাকা </h5>
                                            <h5 className="checkout-item-total">মোট পেমেন্টঃ {this.state.totalPayment}/- টাকা </h5>
                                            <select onChange={e=>{this.setState({paymentMethod:e.target.value})}} className="form-control mt-3">
                                                <option value="">পেমেন্ট মেথড নির্বাচন </option>
                                                <option value="SSL">SSL Commerce </option>
                                            </select>
                                            <button onClick={this.next} className="btn btn-block mt-3 btn-dark">পরবর্তী ধাপে যান</button>
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-center  col-lg-6 col-sm-12 col-12  ">
                                        <div className="login-form">
                                            <div className={this.state.CouponInputDiv}>
                                                <img  className="w-25" src={coupon}/>
                                                <h5>কুপন থাকলে ব্যাবহার করুন </h5>
                                                <input className="form-control m-1" onChange={e=>{this.setState({CouponCode:e.target.value})}} type="text" placeholder="কুপন কোড যদি থাকে"/>
                                                <button onClick={this.applyCoupon} className="btn m-1 btn-success">কুপন নিশ্চিত করুন</button>
                                            </div>
                                            <div className={this.state.ImageDiv}>
                                                <img alt="" className="w-100" src="/images/payment.webp"/>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FullScreenLoader isLoading={this.state.isLoading}/>
                {this.RedirectToCartPage()}
            </Fragment>
        );
    }
}
export default CheckOut;