import SessionHelper from "../Helper/SessionHelper";

class FavRequest{
    addFavURL(){
        return process.env.REACT_APP_BASEURL+"addFav"
    }
    addFavBody(product_code){
        return {
            user_no:SessionHelper.getMobile(),
            product_code:product_code
        }
    }
    removeFavURL(){
        return process.env.REACT_APP_BASEURL+"removeFav"
    }
    removeFavBody(code){
        return {
            "user_no":SessionHelper.getMobile(),
            "product_code":code
        }
    }
    selectFavURL(){
        return process.env.REACT_APP_BASEURL+"favList/"+SessionHelper.getMobile()
    }
}
export const{
    removeFavBody,
    removeFavURL,
    addFavURL,
    addFavBody,
    selectFavURL
}= new FavRequest()