import React, {Component,Fragment} from 'react';
import axios from "axios";
import NotificationPlaceholder from "../Notification/NotificationPlaceholder";
import {ListGroup} from "react-bootstrap";
import NoDataFound from "../Common/NoDataFound";
import {OrderInvoiceListURL} from "../../APIServices/OrderListRequest";
import {Link} from "react-router-dom";

class OrderHistory extends Component {
    constructor() {
        super();
        this.state={
            DataList:null,
            nodata:false,
        }
    }
    componentDidMount() {
        this.SelectInvoices();
    }
    SelectInvoices=()=>{
        axios.get(OrderInvoiceListURL())
            .then((res)=>{
                if(res.status===200 && res.data.length>0){
                    this.setState({DataList:res.data})
                }
                else if(res.data.length===0){
                    this.setState({nodata:true})
                }
                else{
                    this.setState({nodata:true})
                }
            }).catch((err)=>{
            this.setState({nodata:true})
        })
    }



    render() {
        let DataList=this.state.DataList;
        let DataView=<NotificationPlaceholder/>
        if(DataList!==null){
            DataView=DataList.map((list,i)=>{
                return(
                    <ListGroup.Item className="animated slideInUp">
                        <h1 className="notification-title"> ইনভয়েস নংঃ {list['invoice_no']}  ||  অর্ডারের তারিখ: {list['order_date']}</h1>
                        <h1 className="notification-des">
                            <b>টোটাল পেমেন্টঃ</b> {list['total_payment']} টাকা  ||   <b>অর্ডার স্টাটাসঃ</b> {list['order_status']}
                        </h1>
                        <h1 className="notification-des">
                            <b> শিপিং পয়েন্টঃ </b>{list['area']}, {list['shipping_point']}<br/>
                            <b> গ্রাহকের নামঃ </b>{list['receiver_name']}<br/>
                            <b> মোবাইল নংঃ </b>{list['receiver_mobile']}<br/>
                            <b> ঠিকানাঃ </b>{list['address']}
                        </h1>

                        <Link to={"/orderHistoryDetails/"+list['invoice_no']} className="btn btn-dark">পন্য গুলি</Link><br/>
                    </ListGroup.Item>
                )
            })
        }
        else if(this.state.nodata===true){
            DataView=<NoDataFound/>
        }

        return (
            <Fragment>
                <div className="container shadow-sm bg-white my-4 ">
                    <div className="row">
                        <div className="col-lg-12 py-4 col-md-121 col-sm-12 col-12">
                            <ListGroup variant="flush">
                                {DataView}
                            </ListGroup>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default OrderHistory;