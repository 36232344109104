import React, {Component,Fragment} from 'react';
import img from "../../Assets/Img/ph-logo.png";
class CartListPlaceholder extends Component {
    render() {
        return (
            <Fragment>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-1">
                    <div className="container-fluid shadow-sm bg-white">
                        <div className="row">
                            <div className="col-lg-4 text-center p-2 m-auto col-md-4 col-sm-4 col-4">
                                <img className="w-75" src={img}/>
                            </div>
                            <div className="col-lg-8 p-2 m-auto col-md-8 col-sm-8 col-8">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-6 col-lg-6 col-sm-12 col-12">
                                            <div className="ph-item">
                                                <div className="ph-col-12">
                                                    <div className="ph-row">
                                                        <div className="ph-col-12 small"/>
                                                        <div className="ph-col-12 small"/>
                                                        <div className="ph-col-12 small"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-sm-12 col-12">
                                            <div className="ph-item">
                                                <div className="ph-col-12">
                                                    <div className="ph-row">
                                                        <div className="ph-col-12 small"/>
                                                        <div className="ph-col-12 small"/>
                                                        <div className="ph-col-12 small"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-1">
                    <div className="container-fluid shadow-sm bg-white">
                        <div className="row">
                            <div className="col-lg-4 text-center p-2 m-auto col-md-4 col-sm-4 col-4">
                                <img className="w-75" src={img}/>
                            </div>
                            <div className="col-lg-8 p-2 m-auto col-md-8 col-sm-8 col-8">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-6 col-lg-6 col-sm-12 col-12">
                                            <div className="ph-item">
                                                <div className="ph-col-12">
                                                    <div className="ph-row">
                                                        <div className="ph-col-12 small"/>
                                                        <div className="ph-col-12 small"/>
                                                        <div className="ph-col-12 small"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-sm-12 col-12">
                                            <div className="ph-item">
                                                <div className="ph-col-12">
                                                    <div className="ph-row">
                                                        <div className="ph-col-12 small"/>
                                                        <div className="ph-col-12 small"/>
                                                        <div className="ph-col-12 small"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-1">
                    <div className="container-fluid shadow-sm bg-white">
                        <div className="row">
                            <div className="col-lg-4 text-center p-2 m-auto col-md-4 col-sm-4 col-4">
                                <img className="w-75" src={img}/>
                            </div>
                            <div className="col-lg-8 p-2 m-auto col-md-8 col-sm-8 col-8">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-6 col-lg-6 col-sm-12 col-12">
                                            <div className="ph-item">
                                                <div className="ph-col-12">
                                                    <div className="ph-row">
                                                        <div className="ph-col-12 small"/>
                                                        <div className="ph-col-12 small"/>
                                                        <div className="ph-col-12 small"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-sm-12 col-12">
                                            <div className="ph-item">
                                                <div className="ph-col-12">
                                                    <div className="ph-row">
                                                        <div className="ph-col-12 small"/>
                                                        <div className="ph-col-12 small"/>
                                                        <div className="ph-col-12 small"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-1">
                    <div className="container-fluid shadow-sm bg-white">
                        <div className="row">
                            <div className="col-lg-4 text-center p-2 m-auto col-md-4 col-sm-4 col-4">
                                <img className="w-75" src={img}/>
                            </div>
                            <div className="col-lg-8 p-2 m-auto col-md-8 col-sm-8 col-8">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-6 col-lg-6 col-sm-12 col-12">
                                            <div className="ph-item">
                                                <div className="ph-col-12">
                                                    <div className="ph-row">
                                                        <div className="ph-col-12 small"/>
                                                        <div className="ph-col-12 small"/>
                                                        <div className="ph-col-12 small"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-sm-12 col-12">
                                            <div className="ph-item">
                                                <div className="ph-col-12">
                                                    <div className="ph-row">
                                                        <div className="ph-col-12 small"/>
                                                        <div className="ph-col-12 small"/>
                                                        <div className="ph-col-12 small"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default CartListPlaceholder;