// Reviewed
import React, {Component,Fragment} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { HiOutlineChevronRight } from "react-icons/hi";
import { NavLink} from "react-router-dom";
import {AiFillStar, AiOutlineStar} from "react-icons/ai";
import axios from "axios";
import {ProductListNewURL} from "../../APIServices/ProductListRequest";
import NewArrivalPlaceholder from "./NewArrivalPlaceholder";
class NewArrival extends Component {
    constructor(props) {
        super(props);
        this.next=this.next.bind(this);
        this.previous=this.previous.bind(this);
        this.state={
            DataList:null
        }
    }
    next(){
        this.slider.slickNext();
    }
    previous(){
        this.slider.slickPrev();
    }
    componentDidMount() {
        this.SelectProductList()
    }
    SelectProductList=()=>{
        axios.get(ProductListNewURL()).then((res)=>{
            this.setState({DataList:res.data})
        })
    }
    render() {
        let settings = {
            dots: false,
            infinite: true,
            loop:true,
            speed: 500,
            autoplaySpeed:1800,
            autoplay:true,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        let ProductList=this.state.DataList;
        let ProductListView=<NewArrivalPlaceholder/>;

        let OneStarView=<span>
            <AiFillStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
        </span>
        let TwoStarView=<span>
            <AiFillStar className="text-success"/>
             <AiFillStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
        </span>
        let ThreeStarView=<span>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
        </span>
        let FourStarView=<span>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
        </span>
        let FiveStarView=<span>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
        </span>

        if(ProductList!=null){
            ProductListView=ProductList.map((list,i)=>{
                // Product Star Conditions
                let StarView;
                if(list['star']==="1"){
                    StarView=OneStarView;
                }
                else if(list['star']==="2") {
                    StarView=TwoStarView;
                }
                else if(list['star']==="3") {
                    StarView=ThreeStarView;
                }
                else if(list['star']==="4") {
                    StarView=FourStarView;
                }
                else if(list['star']==="5") {
                    StarView=FiveStarView;
                }

                // Product Stock YES NO
                // Discount View Percentage
                let StockView=<span></span>;
                if(list['stock']==="NO"){
                    StockView=<span className="top-left badge red">স্টক আউট</span>;
                }
                else if(list['stock']==="CS"){
                    StockView=<span className="top-left badge blue">শীঘ্রই আসছে</span>;
                }
                else if(list['stock']==="YES" && list['discount_type']==="Percentage" ){
                    StockView=<span className="top-left badge green">{list['discount_percentage']} % অফ</span>
                }
                else if(list['stock']==="YES" && list['discount_type']==="Flat" ){
                    StockView=<span className="top-left badge green">{list['flat_discount']} টাকা ডিস্কাউন্ট</span>
                }
                else if(list['stock']==="YES" && list['discount_type']==="NA" ){
                    StockView=<span></span>;
                }

                let unit_price=list['unit_price'];
                let product_price=list['product_price'];
                let unit=list['unit'];
                // Price View
                let PriceView=<h6 className="card-price-title m-0"> <span className="h4 text-danger">৳ </span> <strike>{unit_price}</strike> {list['product_price']} টাকা/{unit}</h6>

                if(product_price===null){
                    PriceView=<h6 className="card-price-title m-0"> <span className="h4 text-danger">৳ </span> {unit_price} টাকা/{unit}</h6>
                }

                return(
                    <div className="text-center p-2">
                        <div className="card animated zoomIn h-100 w-100 text-center">
                            <div className="badge-overlay">
                                {StockView}
                            </div>
                            <NavLink exact to={"/ProductDetails/"+list['product_code']}>
                            <div className="card-body card-body-custom image-box">
                                <img className="w-100" src={list['image']} />
                                <h5 className="card-title m-0">{list['title']}</h5>
                                {StarView}
                                {PriceView}
                            </div>
                            </NavLink>
                        </div>
                    </div>
                )
            })
        }
        return (
            <Fragment>
                <div className="container-fluid" >
                        <h5 className="section-title text-center"> নতুন পন্য
                            <button className="btn text-white btn-sm ml-2 btn-dark" onClick={this.previous} >
                                <HiOutlineChevronLeft className="text-white"/>
                            </button>
                            <button className="btn text-white  btn-sm ml-2 btn-dark" onClick={this.next}>
                                <HiOutlineChevronRight className="text-white"/>
                            </button>
                        </h5>
                        <div className="slider-overflow">
                            <Slider ref={c=>(this.slider=c)} {...settings}>
                                {ProductListView}
                            </Slider>
                        </div>
                    </div>
            </Fragment>
        );
    }
}
export default NewArrival;