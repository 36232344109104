import React, {Component, Fragment} from 'react';
import noreview from "../../Assets/Img/noreview.svg";
class NoReviewFound extends Component {
    render() {
        return (
            <Fragment>
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-4 text-center noreview-div col-md-4 col-sm-6 col-6">
                            <img alt="nodata" className="nodata-img" src={noreview}/>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default NoReviewFound;