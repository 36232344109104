import React, {Component,Fragment} from 'react';
import {Link} from "react-router-dom";

class BlogList extends Component {
    render() {
        return (
            <Fragment>
                <div className="container-fluid mt-4">
                    <div className="videoTopSection bg-white p-3">
                        <a href="/">Home</a> /
                        <a className="ml-2" href="/">BlogList </a>
                    </div>
                    <div className="row mt-2">
                        <div className="col-lg-2 col-md-3 col-sm-4 col-12">
                            <div className="card blogCard p-3 mt-2 mb-2">
                                <h6 className="blogTitle">মোবাইল অ্যাপে মিলছে রাজশাহীর আম</h6>
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <p className="blogDes">রাজশাহীর আমের অ্যাপভিত্তিক বিক্রয় সেবা চালু হয়েছে। এর মাধ্যমে শুধু রাজশাহীর আম সরবরাহ করা হবে। সেই সঙ্গে...</p>
                                <Link className="readText" to="/BlogDetails">আরও পড়ুন</Link>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-4 col-12">
                            <div className="card blogCard p-3 mt-2 mb-2">
                                <h6 className="blogTitle">মোবাইল অ্যাপে মিলছে রাজশাহীর আম</h6>
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <p className="blogDes">রাজশাহীর আমের অ্যাপভিত্তিক বিক্রয় সেবা চালু হয়েছে। এর মাধ্যমে শুধু রাজশাহীর আম সরবরাহ করা হবে। সেই সঙ্গে...</p>
                                <Link className="readText" to="/BlogDetails">আরও পড়ুন</Link>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-4 col-12">
                            <div className="card blogCard p-3 mt-2 mb-2">
                                <h6 className="blogTitle">মোবাইল অ্যাপে মিলছে রাজশাহীর আম</h6>
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <p className="blogDes">রাজশাহীর আমের অ্যাপভিত্তিক বিক্রয় সেবা চালু হয়েছে। এর মাধ্যমে শুধু রাজশাহীর আম সরবরাহ করা হবে। সেই সঙ্গে...</p>
                                <Link className="readText" to="/BlogDetails">আরও পড়ুন</Link>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-4 col-12">
                            <div className="card blogCard p-3 mt-2 mb-2">
                                <h6 className="blogTitle">মোবাইল অ্যাপে মিলছে রাজশাহীর আম</h6>
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <p className="blogDes">রাজশাহীর আমের অ্যাপভিত্তিক বিক্রয় সেবা চালু হয়েছে। এর মাধ্যমে শুধু রাজশাহীর আম সরবরাহ করা হবে। সেই সঙ্গে...</p>
                                <Link className="readText" to="/BlogDetails">আরও পড়ুন</Link>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-4 col-12">
                            <div className="card blogCard p-3 mt-2 mb-2">
                                <h6 className="blogTitle">মোবাইল অ্যাপে মিলছে রাজশাহীর আম</h6>
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <p className="blogDes">রাজশাহীর আমের অ্যাপভিত্তিক বিক্রয় সেবা চালু হয়েছে। এর মাধ্যমে শুধু রাজশাহীর আম সরবরাহ করা হবে। সেই সঙ্গে...</p>
                                <Link className="readText" to="/BlogDetails">আরও পড়ুন</Link>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-4 col-12">
                            <div className="card blogCard p-3 mt-2 mb-2">
                                <h6 className="blogTitle">মোবাইল অ্যাপে মিলছে রাজশাহীর আম</h6>
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <p className="blogDes">রাজশাহীর আমের অ্যাপভিত্তিক বিক্রয় সেবা চালু হয়েছে। এর মাধ্যমে শুধু রাজশাহীর আম সরবরাহ করা হবে। সেই সঙ্গে...</p>
                                <Link className="readText" to="/BlogDetails">আরও পড়ুন</Link>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-4 col-12">
                            <div className="card blogCard p-3 mt-2 mb-2">
                                <h6 className="blogTitle">মোবাইল অ্যাপে মিলছে রাজশাহীর আম</h6>
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <p className="blogDes">রাজশাহীর আমের অ্যাপভিত্তিক বিক্রয় সেবা চালু হয়েছে। এর মাধ্যমে শুধু রাজশাহীর আম সরবরাহ করা হবে। সেই সঙ্গে...</p>
                                <Link className="readText" to="/BlogDetails">আরও পড়ুন</Link>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-4 col-12">
                            <div className="card blogCard p-3 mt-2 mb-2">
                                <h6 className="blogTitle">মোবাইল অ্যাপে মিলছে রাজশাহীর আম</h6>
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <p className="blogDes">রাজশাহীর আমের অ্যাপভিত্তিক বিক্রয় সেবা চালু হয়েছে। এর মাধ্যমে শুধু রাজশাহীর আম সরবরাহ করা হবে। সেই সঙ্গে...</p>
                                <Link className="readText" to="/BlogDetails">আরও পড়ুন</Link>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-4 col-12">
                            <div className="card blogCard p-3 mt-2 mb-2">
                                <h6 className="blogTitle">মোবাইল অ্যাপে মিলছে রাজশাহীর আম</h6>
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <p className="blogDes">রাজশাহীর আমের অ্যাপভিত্তিক বিক্রয় সেবা চালু হয়েছে। এর মাধ্যমে শুধু রাজশাহীর আম সরবরাহ করা হবে। সেই সঙ্গে...</p>
                                <Link className="readText" to="/BlogDetails">আরও পড়ুন</Link>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-4 col-12">
                            <div className="card blogCard p-3 mt-2 mb-2">
                                <h6 className="blogTitle">মোবাইল অ্যাপে মিলছে রাজশাহীর আম</h6>
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <p className="blogDes">রাজশাহীর আমের অ্যাপভিত্তিক বিক্রয় সেবা চালু হয়েছে। এর মাধ্যমে শুধু রাজশাহীর আম সরবরাহ করা হবে। সেই সঙ্গে...</p>
                                <Link className="readText" to="/BlogDetails">আরও পড়ুন</Link>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-4 col-12">
                            <div className="card blogCard p-3 mt-2 mb-2">
                                <h6 className="blogTitle">মোবাইল অ্যাপে মিলছে রাজশাহীর আম</h6>
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <p className="blogDes">রাজশাহীর আমের অ্যাপভিত্তিক বিক্রয় সেবা চালু হয়েছে। এর মাধ্যমে শুধু রাজশাহীর আম সরবরাহ করা হবে। সেই সঙ্গে...</p>
                                <Link className="readText" to="/BlogDetails">আরও পড়ুন</Link>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-4 col-12">
                            <div className="card blogCard p-3 mt-2 mb-2">
                                <h6 className="blogTitle">মোবাইল অ্যাপে মিলছে রাজশাহীর আম</h6>
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <p className="blogDes">রাজশাহীর আমের অ্যাপভিত্তিক বিক্রয় সেবা চালু হয়েছে। এর মাধ্যমে শুধু রাজশাহীর আম সরবরাহ করা হবে। সেই সঙ্গে...</p>
                                <Link className="readText" to="/BlogDetails">আরও পড়ুন</Link>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-4 col-12">
                            <div className="card blogCard p-3 mt-2 mb-2">
                                <h6 className="blogTitle">মোবাইল অ্যাপে মিলছে রাজশাহীর আম</h6>
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <p className="blogDes">রাজশাহীর আমের অ্যাপভিত্তিক বিক্রয় সেবা চালু হয়েছে। এর মাধ্যমে শুধু রাজশাহীর আম সরবরাহ করা হবে। সেই সঙ্গে...</p>
                                <Link className="readText" to="/BlogDetails">আরও পড়ুন</Link>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-4 col-12">
                            <div className="card blogCard p-3 mt-2 mb-2">
                                <h6 className="blogTitle">মোবাইল অ্যাপে মিলছে রাজশাহীর আম</h6>
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <p className="blogDes">রাজশাহীর আমের অ্যাপভিত্তিক বিক্রয় সেবা চালু হয়েছে। এর মাধ্যমে শুধু রাজশাহীর আম সরবরাহ করা হবে। সেই সঙ্গে...</p>
                                <Link className="readText" to="/BlogDetails">আরও পড়ুন</Link>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-4 col-12">
                            <div className="card blogCard p-3 mt-2 mb-2">
                                <h6 className="blogTitle">মোবাইল অ্যাপে মিলছে রাজশাহীর আম</h6>
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <p className="blogDes">রাজশাহীর আমের অ্যাপভিত্তিক বিক্রয় সেবা চালু হয়েছে। এর মাধ্যমে শুধু রাজশাহীর আম সরবরাহ করা হবে। সেই সঙ্গে...</p>
                                <Link className="readText" to="/BlogDetails">আরও পড়ুন</Link>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-4 col-12">
                            <div className="card blogCard p-3 mt-2 mb-2">
                                <h6 className="blogTitle">মোবাইল অ্যাপে মিলছে রাজশাহীর আম</h6>
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <p className="blogDes">রাজশাহীর আমের অ্যাপভিত্তিক বিক্রয় সেবা চালু হয়েছে। এর মাধ্যমে শুধু রাজশাহীর আম সরবরাহ করা হবে। সেই সঙ্গে...</p>
                                <Link className="readText" to="/BlogDetails">আরও পড়ুন</Link>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-4 col-12">
                            <div className="card blogCard p-3 mt-2 mb-2">
                                <h6 className="blogTitle">মোবাইল অ্যাপে মিলছে রাজশাহীর আম</h6>
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <p className="blogDes">রাজশাহীর আমের অ্যাপভিত্তিক বিক্রয় সেবা চালু হয়েছে। এর মাধ্যমে শুধু রাজশাহীর আম সরবরাহ করা হবে। সেই সঙ্গে...</p>
                                <Link className="readText" to="/BlogDetails">আরও পড়ুন</Link>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-4 col-12">
                            <div className="card blogCard p-3 mt-2 mb-2">
                                <h6 className="blogTitle">মোবাইল অ্যাপে মিলছে রাজশাহীর আম</h6>
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <p className="blogDes">রাজশাহীর আমের অ্যাপভিত্তিক বিক্রয় সেবা চালু হয়েছে। এর মাধ্যমে শুধু রাজশাহীর আম সরবরাহ করা হবে। সেই সঙ্গে...</p>
                                <Link className="readText" to="/BlogDetails">আরও পড়ুন</Link>
                            </div>
                        </div>

                    </div>
                </div>
            </Fragment>
        );
    }
}

export default BlogList;