//Reviewed
import React, {Component,Fragment} from 'react';
import img from "../../Assets/Img/ph-logo.png"
class ProductListPlaceholder extends Component {
    render() {
        return (
            <Fragment>
                <div className="col-lg-3 col-md-4 col-sm-6 col-6 p-1">
                    <div className="card h-100 w-100 text-center">
                        <div className="card-body card-body-custom image-box">
                            <img className="w-100" src={img} />
                            <br/>
                            <div className="ph-item">
                                <div className="ph-col-12">
                                    <div className="ph-row">
                                        <div className="ph-col-12 small"/>
                                        <div className="ph-col-12 small"/>
                                        <div className="ph-col-12 small"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 col-6 p-1">
                    <div className="card h-100 w-100 text-center">
                        <div className="card-body card-body-custom image-box">
                            <img className="w-100" src={img} />
                            <br/>
                            <div className="ph-item">
                                <div className="ph-col-12">
                                    <div className="ph-row">
                                        <div className="ph-col-12 small"/>
                                        <div className="ph-col-12 small"/>
                                        <div className="ph-col-12 small"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 col-6 p-1">
                    <div className="card h-100 w-100 text-center">
                        <div className="card-body card-body-custom image-box">
                            <img className="w-100" src={img} />
                            <br/>
                            <div className="ph-item">
                                <div className="ph-col-12">
                                    <div className="ph-row">
                                        <div className="ph-col-12 small"/>
                                        <div className="ph-col-12 small"/>
                                        <div className="ph-col-12 small"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 col-6 p-1">
                    <div className="card h-100 w-100 text-center">
                        <div className="card-body card-body-custom image-box">
                            <img className="w-100" src={img} />
                            <br/>
                            <div className="ph-item">
                                <div className="ph-col-12">
                                    <div className="ph-row">
                                        <div className="ph-col-12 small"/>
                                        <div className="ph-col-12 small"/>
                                        <div className="ph-col-12 small"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 col-6 p-1">
                    <div className="card h-100 w-100 text-center">
                        <div className="card-body card-body-custom image-box">
                            <img className="w-100" src={img} />
                            <br/>
                            <div className="ph-item">
                                <div className="ph-col-12">
                                    <div className="ph-row">
                                        <div className="ph-col-12 small"/>
                                        <div className="ph-col-12 small"/>
                                        <div className="ph-col-12 small"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 col-6 p-1">
                    <div className="card h-100 w-100 text-center">
                        <div className="card-body card-body-custom image-box">
                            <img className="w-100" src={img} />
                            <br/>
                            <div className="ph-item">
                                <div className="ph-col-12">
                                    <div className="ph-row">
                                        <div className="ph-col-12 small"/>
                                        <div className="ph-col-12 small"/>
                                        <div className="ph-col-12 small"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 col-6 p-1">
                    <div className="card h-100 w-100 text-center">
                        <div className="card-body card-body-custom image-box">
                            <img className="w-100" src={img} />
                            <br/>
                            <div className="ph-item">
                                <div className="ph-col-12">
                                    <div className="ph-row">
                                        <div className="ph-col-12 small"/>
                                        <div className="ph-col-12 small"/>
                                        <div className="ph-col-12 small"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 col-6 p-1">
                    <div className="card h-100 w-100 text-center">
                        <div className="card-body card-body-custom image-box">
                            <img className="w-100" src={img} />
                            <br/>
                            <div className="ph-item">
                                <div className="ph-col-12">
                                    <div className="ph-row">
                                        <div className="ph-col-12 small"/>
                                        <div className="ph-col-12 small"/>
                                        <div className="ph-col-12 small"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default ProductListPlaceholder;