import SessionHelper from "../Helper/SessionHelper";

class SubmitReviewRequest {
    SubmitReviewURL(){
        return process.env.REACT_APP_BASEURL+"AddReview"
    }
    SubmitReviewBody(code,product_name,name,comments,rating){
        return{
            "product_code":code,
            "product_name":product_name,
            "mobile":SessionHelper.getMobile(),
            "reviewer_name":name,
            "reviewer_rating":rating,
            "reviewer_comments":comments,
        }
    }
}
export const {SubmitReviewURL,SubmitReviewBody}=new SubmitReviewRequest();