import React, {Component,Fragment} from 'react';
import TopNavigation from "../../Components/Common/TopNavigation";
import TopNavigationMobile from "../../Components/Common/TopNavigationMobile";
import Footer from "../../Components/Common/Footer";
import ProductList from "../../Components/ProductList/ProductList";
import FooterMobile from "../../Components/Common/FooterMobile";

class ProductListPage extends Component {

    constructor({match}) {
        super();
        this.state={
            code:match.params.code,
        }
    }

    componentDidMount() {

        window.scroll(0,0)

    }

    render() {
        return (
            <Fragment>

                <TopNavigation/>

                <div className="mobile nav-mobile-top">
                    <TopNavigationMobile/>
                </div>

                <ProductList code={this.state.code}/>
                <div className="desktop">
                    <Footer/>
                </div>
                <div className="mobile">
                    <FooterMobile/>
                </div>
            </Fragment>
        );
    }
}

export default ProductListPage;