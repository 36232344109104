import cogoToast from "cogo-toast";

class ToastMassageHelper{

    nameRequired(){
        cogoToast.error("আপনার নাম লিখুন",{position:"bottom-center"})
    }
    otpSuccessMobile(){
        cogoToast.success("আপনার মোবাইলে 6 সংখ্যার ভেরিফিকেশন কোড পাঠানো হয়েছে ",{position:"bottom-center"})
    }
    otpSuccessEmail(){
        cogoToast.success("আপনার ইমেইল ঠিকানায় 6 সংখ্যার ভেরিফিকেশন কোড পাঠানো হয়েছে ",{position:"bottom-center"})
    }
    mobileRequired(){
        cogoToast.error("আপনার মোবাইল নম্বর লিখুন",{position:"bottom-center"})
    }
    emailRequired(){
        cogoToast.error("আপনার ইমেইল লিখুন",{position:"bottom-center"})
    }
    invalidEmail(){
        cogoToast.error("ইমেইল সঠিক নয়",{position:"bottom-center"})
    }
    msgRequired(){
        cogoToast.error("আপনার বার্তা লিখুন",{position:"bottom-center"})
    }
    requestFail(){
        cogoToast.error("দুঃখিত ! আবার চেষ্টা করুন",{position:"bottom-center"})
    }
    contactSuccess(){
        cogoToast.success("আপনার বার্তা পাঠানো হয়েছে",{position:"bottom-center"})
    }
    otpRequired(){
        cogoToast.error("ভেরিফিকেশন কোড লিখুন",{position:"bottom-center"})
    }
    loginSuccess(){
        cogoToast.success("লগ-ইন সপন্ন হয়েছে",{position:"bottom-center"})
    }
    addFavSuccess(){
        cogoToast.success("পছন্দের তালিকায় যুক্ত হয়েছে ",{position:"bottom-center"})
    }
    removeItemSuccess(){
        cogoToast.success(" আইটেম রিমুভ হয়েছে ",{position:"bottom-center"})
    }
    sizeRequired(){
        cogoToast.error("সাইজ নির্বাচন করুন !",{position:"bottom-center"})
    }
    quantityRequired(){
        cogoToast.error("পরিমান নির্বাচন করুন !",{position:"bottom-center"})
    }
    AddCartSuccess(){
        cogoToast.success("কার্টে আইটেম যুক্ত হয়েছে",{position:"bottom-center"})
    }
    RequiredPaymentMethod(){
        cogoToast.error("পেমেন্ট মেথড নির্বাচন করুন!",{position:"bottom-center"})
    }
    RequiredSearchKey(){
        cogoToast.error("আপনি কি খুজচ্ছেন? লিখে সার্চ করুন!",{position:"bottom-center"})
    }

    RequiredStock(){
        cogoToast.error("দুঃখিত ! পন্যটি স্টকে নেই ",{position:"bottom-center"})
    }
    RequiredCartList(){
        cogoToast.error("আপনার কার্টে পন্য নেই",{position:"bottom-center"})
    }
    RequiredCity(){
        cogoToast.error("শহর নির্বাচন করুন",{position:"bottom-center"})
    }
    RequiredLocation(){
        cogoToast.error("অঞ্চল নির্বাচন করুন",{position:"bottom-center"})
    }
    RequiredShippingPoint(){
        cogoToast.error("শিপিং পয়েন্ট নির্বাচন করুন",{position:"bottom-center"})
    }
    RequiredReceiverName(){
        cogoToast.error("গ্রাহকের নাম লিখুন",{position:"bottom-center"})
    }
    RequiredReceiverPhone(){
        cogoToast.error("গ্রাহকের সঠিক মোবাইল নং লিখুন",{position:"bottom-center"})
    }
    RequiredReceiverAddress(){
        cogoToast.error("গ্রাহকের ঠিকানা লিখুন",{position:"bottom-center"})
    }
    comingSoon(){
        cogoToast.info("পণ্যটি শীঘ্রই আসবে",{position:"bottom-center"})
    }
    ReviewerNameRequired(){
        cogoToast.error("আপনার নাম লিখুন",{position:"bottom-center"})
    }
    ReviewerCommentsRequired(){
        cogoToast.error("আপনার মন্তব্য লিখুন",{position:"bottom-center"})
    }
    ReviewSuccess(){
        cogoToast.success("আপনার রিভিউ সাবমিট হয়েছে",{position:"bottom-center"})
    }

    PaymentVerify(){
        cogoToast.success("পেমেন্ট ভেরিফিকেশন সম্পন্ন হয়েছে",{position:"bottom-center"})
    }

    OrderPlaceSuccess(){
        cogoToast.success("অর্ডার গ্রহন করা হয়েছে",{position:"bottom-center"})
    }

    OrderPlaceInProgress(){
        cogoToast.info("অনুগ্রহ করে অপেক্ষা করুন",{position:"bottom-center"})
    }

    emptyCoupon(){
        cogoToast.error("কুপন কোড লিখুন",{position:"bottom-center"})
    }

    invalidCoupon(){
        cogoToast.error("কুপন কোড সঠিক নয়",{position:"bottom-center"})
    }
    CouponMinimunPrice(Price){
        cogoToast.error("কুপনটি ব্যাবহারের জন্যে পন্যের দাম কমপক্ষে "+Price+" টাকা হতে হবে",{position:"bottom-center"})
    }

    CouponSuccess(CouponAmount){
        cogoToast.success("কুপনটি ব্যাবহারের ডিস্কাউন্ট পেলেন "+CouponAmount+" টাকা ",{position:"bottom-center"})
    }


}
export const {
    CouponSuccess,
    CouponMinimunPrice,
    emptyCoupon,
    invalidCoupon,
    OrderPlaceInProgress,
    OrderPlaceSuccess,
    PaymentVerify,
    ReviewSuccess,
    ReviewerNameRequired,
    ReviewerCommentsRequired,
    comingSoon,
    RequiredReceiverAddress,
    RequiredReceiverPhone,
    RequiredReceiverName,
    RequiredShippingPoint,
    RequiredCity,
    RequiredLocation,
    RequiredCartList,
    RequiredStock,
    RequiredSearchKey,
    RequiredPaymentMethod,
    AddCartSuccess,
    sizeRequired,
    quantityRequired,
    removeItemSuccess,
    addFavSuccess,
    loginSuccess,
    otpRequired,
    otpSuccessMobile,
    otpSuccessEmail,
    emailRequired,
    invalidEmail,
    nameRequired,
    mobileRequired,
    msgRequired,
    requestFail,
    contactSuccess
}=new ToastMassageHelper()