// Reviewed
import React, {Component, Fragment} from 'react';
class NewArrivalPlaceholder extends Component {
    render() {
        return (
        <Fragment>
        </Fragment>
        );
    }
}

export default NewArrivalPlaceholder;