import React, {Component,Fragment} from 'react';
import {FaPlayCircle} from "react-icons/fa";
import {Nav} from "react-bootstrap";
import {Link} from "react-router-dom";

class VideoList extends Component {
    render() {
        return (

            <Fragment>
                <div className="container-fluid mt-4">
                    <div className="videoTopSection bg-white p-3">
                        <a href="/">Home</a> /
                        <a className="ml-2" href="/">VideoList </a>
                    </div>
                    <div className="row mt-2">

                        <div className="col-lg-2 col-md-3 col-sm-6 col-12">
                            <div className="card videoCard p-2 mt-2 mb-2">
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <h6 className="videoTitle">মাত্র ২৪ ঘণ্টায় রাজশাহীর সুস্বাদু আম পাওয়া যাচ্ছে</h6>
                                <Link className="playBtn text-center" to="/VideoDetails"><FaPlayCircle/></Link>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-6 col-12">
                            <div className="card videoCard p-2 mt-2 mb-2">
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <h6 className="videoTitle">মাত্র ২৪ ঘণ্টায় রাজশাহীর সুস্বাদু আম পাওয়া যাচ্ছে</h6>
                                <Link className="playBtn text-center" to="/VideoDetails"><FaPlayCircle/></Link>
                            </div>
                        </div>


                        <div className="col-lg-2 col-md-3 col-sm-6 col-12">
                            <div className="card videoCard p-2 mt-2 mb-2">
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <h6 className="videoTitle">মাত্র ২৪ ঘণ্টায় রাজশাহীর সুস্বাদু আম পাওয়া যাচ্ছে</h6>
                                <Link className="playBtn text-center" to="/VideoDetails"><FaPlayCircle/></Link>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-6 col-12">
                            <div className="card videoCard p-2 mt-2 mb-2">
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <h6 className="videoTitle">মাত্র ২৪ ঘণ্টায় রাজশাহীর সুস্বাদু আম পাওয়া যাচ্ছে</h6>
                                <Link className="playBtn text-center" to="/VideoDetails"><FaPlayCircle/></Link>
                            </div>
                        </div>


                        <div className="col-lg-2 col-md-3 col-sm-6 col-12">
                            <div className="card videoCard p-2 mt-2 mb-2">
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <h6 className="videoTitle">মাত্র ২৪ ঘণ্টায় রাজশাহীর সুস্বাদু আম পাওয়া যাচ্ছে</h6>
                                <Link className="playBtn text-center" to="/VideoDetails"><FaPlayCircle/></Link>
                            </div>
                        </div>


                        <div className="col-lg-2 col-md-3 col-sm-6 col-12">
                            <div className="card videoCard p-2 mt-2 mb-2">
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <h6 className="videoTitle">মাত্র ২৪ ঘণ্টায় রাজশাহীর সুস্বাদু আম পাওয়া যাচ্ছে</h6>
                                <Link className="playBtn text-center" to="/VideoDetails"><FaPlayCircle/></Link>
                            </div>
                        </div>


                        <div className="col-lg-2 col-md-3 col-sm-6 col-12">
                            <div className="card videoCard p-2 mt-2 mb-2">
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <h6 className="videoTitle">মাত্র ২৪ ঘণ্টায় রাজশাহীর সুস্বাদু আম পাওয়া যাচ্ছে</h6>
                                <Link className="playBtn text-center" to="/VideoDetails"><FaPlayCircle/></Link>
                            </div>
                        </div>


                        <div className="col-lg-2 col-md-3 col-sm-6 col-12">
                            <div className="card videoCard p-2 mt-2 mb-2">
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <h6 className="videoTitle">মাত্র ২৪ ঘণ্টায় রাজশাহীর সুস্বাদু আম পাওয়া যাচ্ছে</h6>
                                <Link className="playBtn text-center" to="/VideoDetails"><FaPlayCircle/></Link>
                            </div>
                        </div>


                        <div className="col-lg-2 col-md-3 col-sm-6 col-12">
                            <div className="card videoCard p-2 mt-2 mb-2">
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <h6 className="videoTitle">মাত্র ২৪ ঘণ্টায় রাজশাহীর সুস্বাদু আম পাওয়া যাচ্ছে</h6>
                                <Link className="playBtn text-center" to="/VideoDetails"><FaPlayCircle/></Link>
                            </div>
                        </div>


                        <div className="col-lg-2 col-md-3 col-sm-6 col-12">
                            <div className="card videoCard p-2 mt-2 mb-2">
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <h6 className="videoTitle">মাত্র ২৪ ঘণ্টায় রাজশাহীর সুস্বাদু আম পাওয়া যাচ্ছে</h6>
                                <Link className="playBtn text-center" to="/VideoDetails"><FaPlayCircle/></Link>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-6 col-12">
                            <div className="card videoCard p-2 mt-2 mb-2">
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <h6 className="videoTitle">মাত্র ২৪ ঘণ্টায় রাজশাহীর সুস্বাদু আম পাওয়া যাচ্ছে</h6>
                                <Link className="playBtn text-center" to="/VideoDetails"><FaPlayCircle/></Link>
                            </div>
                        </div>


                        <div className="col-lg-2 col-md-3 col-sm-6 col-12">
                            <div className="card videoCard p-2 mt-2 mb-2">
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <h6 className="videoTitle">মাত্র ২৪ ঘণ্টায় রাজশাহীর সুস্বাদু আম পাওয়া যাচ্ছে</h6>
                                <Link className="playBtn text-center" to="/VideoDetails"><FaPlayCircle/></Link>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-6 col-12">
                            <div className="card videoCard p-2 mt-2 mb-2">
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <h6 className="videoTitle">মাত্র ২৪ ঘণ্টায় রাজশাহীর সুস্বাদু আম পাওয়া যাচ্ছে</h6>
                                <Link className="playBtn text-center" to="/VideoDetails"><FaPlayCircle/></Link>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-6 col-12">
                            <div className="card videoCard p-2 mt-2 mb-2">
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <h6 className="videoTitle">মাত্র ২৪ ঘণ্টায় রাজশাহীর সুস্বাদু আম পাওয়া যাচ্ছে</h6>
                                <Link className="playBtn text-center" to="/VideoDetails"><FaPlayCircle/></Link>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-6 col-12">
                            <div className="card videoCard p-2 mt-2 mb-2">
                                <img src="https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?cs=srgb&dl=agriculture-apple-blur-257840.jpg&fm=jpg" alt=""/>
                                <p className="date">10th May, 2021</p>
                                <h6 className="videoTitle">মাত্র ২৪ ঘণ্টায় রাজশাহীর সুস্বাদু আম পাওয়া যাচ্ছে</h6>
                                <Link className="playBtn text-center" to="/VideoDetails"><FaPlayCircle/></Link>
                            </div>
                        </div>

                    </div>
                </div>
            </Fragment>
        );
    }
}

export default VideoList;