// Completed Rabbil Hasan
import React, {Component,Fragment} from 'react';
import {contactSuccess, mobileRequired, msgRequired, nameRequired, requestFail} from "../../Helper/ToastMassageHelper";
import axios from "axios";
import {ContactRequestBody, ContactRequestURL} from "../../APIServices/ContactRequest";
import FullScreenLoader from "../Common/FullScreenLoader";
class ContactSection extends Component {
    constructor() {
        super();
        this.state={
            isLoading:"d-none",
            name:"",
            mobile:"",
            msg:"",
        }
    }
    send=()=>{
        let name=this.state.name;
        let mobile=this.state.mobile;
        let msg=this.state.msg;
        if(name.length===0){
            nameRequired();
        }
        else if(mobile.length===0){
            mobileRequired();
        }
        else if(msg.length===0){
            msgRequired();
        }
        else{
            this.setState({isLoading:""})
            axios.post(ContactRequestURL(),ContactRequestBody(name, mobile, msg))
                .then((res)=>{
                    this.setState({isLoading:"d-none"})
                    if(res.status===200 && res.data===1){
                        contactSuccess();
                        this.setState({name:"", mobile:"", msg:""})
                    }
                    else{
                        requestFail();
                    }
                })
                .catch((err)=>{
                    this.setState({isLoading:"d-none"})
                    requestFail();
                })
        }
    }
    render() {
        return (
            <Fragment>
                <div className="container shadow-sm animated zoomIn bg-white my-4 ">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="container-fluid">
                                <div className="row d-flex justify-content-center">
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-12 p-4">
                                            <br/>
                                            <h4 className="text-center my-3">যোগাযোগ করুন</h4>
                                            <div className="form-group">
                                                <input value={this.state.name} onChange={e=>this.setState({name:e.target.value})} type="text" className="form-control  mt-2" placeholder="আপনার নাম"/>
                                            </div>
                                            <div className="form-group">
                                                <input value={this.state.mobile} onChange={e=>this.setState({mobile:e.target.value})} type="text" className="form-control  mt-2" placeholder="আপনার মোবাইল নম্বর"/>
                                            </div>
                                            <div className="form-group">
                                                <input value={this.state.msg} onChange={e=>this.setState({msg:e.target.value})} type="text" className="form-control  mt-2" placeholder="বার্তা"/>
                                            </div>
                                            <button onClick={this.send} className="btn btn-block btn-dark mt-2">পাঠিয়ে দিন</button>
                                            <br/>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <FullScreenLoader isLoading={this.state.isLoading}/>
            </Fragment>
        );
    }
}
export default ContactSection;