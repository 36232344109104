import React, {Component,Fragment} from 'react';
import {Route, Switch} from "react-router";
import HomePage from "../Pages/HomePage/HomePage";
import ContactPage from "../Pages/ContactPage/ContactPage";
import AboutPage from "../Pages/AboutPage/AboutPage";
import HowToPurchasePage from "../Pages/HowToPurchasePage/HowToPurchasePage";
import PrivacyPage from "../Pages/PrivacyPage/PrivacyPage";
import TermsPage from "../Pages/TermsPage/TermsPage";
import RefundPage from "../Pages/RefundPage/RefundPage";
import BlogListPage from "../Pages/BlogListPage/BlogListPage";
import BlogDetailsPage from "../Pages/BlogDetailsPage/BlogDetailsPage";
import VideoListPage from "../Pages/VideoListPage/VideoListPage";
import LoginPage from "../Pages/LoginPage/LoginPage";
import VideoDetailsPage from "../Pages/VideoDetailsPage/VideoDetailsPage";
import NotificationListPage from "../Pages/NotificationPage/NotificationListPage";
import ProductListPage from "../Pages/ProductListPage/ProductListPage";
import ProductDetailsPage from "../Pages/ProductDetailsPage/ProductDetailsPage";
import OrderListPage from "../Pages/OrderListPage/OrderListPage";
import CartListPage from "../Pages/CartListPage/CartListPage";
import OtpPage from "../Pages/OtpPage/OtpPage";
import FavouritePage from "../Pages/FavouritePage/FavouritePage";
import CheckOutPage from "../Pages/CheckOutPage/CheckOutPage";
import PaymentFailPage from "../Pages/paymentFailPage/paymentFailPage";
import PaymentExistPage from "../Pages/paymentExistPage/paymentExistPage";
import PaymentSuccessPage from "../Pages/paymentSuccessPage/paymentSuccessPage";
import PaymentCancelPage from "../Pages/paymentCancelPage/paymentCancelPage";
import SearchListPage from "../Pages/SearchListPage/SearchListPage";
import OrderHistoryPage from "../Pages/OrderHistoryPage/OrderHistoryPage";
import OrderHistoryDetailsPage from "../Pages/OrderHistoryDetailsPage/OrderHistoryDetailsPage";
class AppRoute extends Component {
    render() {
        return (
            <Fragment>
                <Switch>
                    <Route exact path="/" render={(props) => <HomePage {...props} key={Date.now()} />}/>
                    <Route exact path="/ProductList/:code" render={(props) => <ProductListPage {...props} key={Date.now()} />}/>
                    <Route exact path="/ProductDetails/:code" render={(props) => <ProductDetailsPage {...props} key={Date.now()} />}/>
                    <Route exact path="/search/:code" render={(props) => <SearchListPage {...props} key={Date.now()} />}/>

                    <Route exact path="/CartList" render={(props) => <CartListPage {...props} key={Date.now()} />}/>
                    <Route exact path="/OrderList" render={(props) => <OrderListPage {...props} key={Date.now()} />}/>
                    <Route exact path="/Login" render={(props) => <LoginPage {...props} key={Date.now()} />}/>
                    <Route exact path="/NotificationList" render={(props) => <NotificationListPage {...props} key={Date.now()} />}/>
                    <Route exact path="/BlogList" render={(props) => <BlogListPage {...props} key={Date.now()} />}/>
                    <Route exact path="/BlogDetails" render={(props) => <BlogDetailsPage {...props} key={Date.now()} />}/>
                    <Route exact path="/VideoList" render={(props) => <VideoListPage {...props} key={Date.now()} />}/>
                    <Route exact path="/VideoDetails" render={(props) => <VideoDetailsPage {...props} key={Date.now()} />}/>
                    <Route exact path="/HowPurchase" render={(props) => <HowToPurchasePage {...props} key={Date.now()} />}/>
                    <Route exact path="/About" render={(props) => <AboutPage {...props} key={Date.now()} />}/>
                    <Route exact path="/Contact" render={(props) => <ContactPage {...props} key={Date.now()} />}/>
                    <Route exact path="/Privacy" render={(props) => <PrivacyPage {...props} key={Date.now()} />}/>
                    <Route exact path="/Terms" render={(props) => <TermsPage {...props} key={Date.now()} />}/>
                    <Route exact path="/Refund" render={(props) => <RefundPage {...props} key={Date.now()} />}/>
                    <Route exact path="/fav" render={(props) => <FavouritePage {...props} key={Date.now()} />}/>
                    <Route exact path="/otp" render={(props) => <OtpPage {...props} key={Date.now()} />}/>
                    <Route exact path="/checkOut" render={(props) => <CheckOutPage {...props} key={Date.now()} />}/>

                    //Payment Success And Fail
                    <Route exact path="/paymentFail/:trx" render={(props) => <PaymentFailPage {...props} key={Date.now()} />}/>
                    <Route exact path="/paymentExist/:trx" render={(props) => <PaymentExistPage {...props} key={Date.now()} />}/>
                    <Route exact path="/paymentSuccess/:trx" render={(props) => <PaymentSuccessPage {...props} key={Date.now()} />}/>
                    <Route exact path="/paymentCancel/:trx" render={(props) => <PaymentCancelPage {...props} key={Date.now()} />}/>

                    //Order History
                    <Route exact path="/orderHistory" render={(props) => <OrderHistoryPage {...props} key={Date.now()} />}/>
                    <Route exact path="/orderHistoryDetails/:invoice" render={(props) => <OrderHistoryDetailsPage {...props} key={Date.now()} />}/>


                </Switch>
            </Fragment>
        );
    }
}

export default AppRoute;