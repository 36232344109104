import React, {Component,Fragment} from 'react';
import TopNavigation from "../../Components/Common/TopNavigation";
import TopNavigationMobile from "../../Components/Common/TopNavigationMobile";
import Footer from "../../Components/Common/Footer";
import FooterMobile from "../../Components/Common/FooterMobile";
import {Redirect} from "react-router";
import SessionHelper from "../../Helper/SessionHelper";
import OrderHistoryDetails from "../../Components/OrderHistoryDetails/OrderHistoryDetails";

class OrderHistoryDetailsPage extends Component {

    constructor({match}) {
        super();
        this.state={
            invoice:match.params.invoice,
            RedirectWithoutLogin:false,
        }
    }

    pageRedirectWithoutLogin=()=>{
        if(this.state.RedirectWithoutLogin===true){
            return(<Redirect to="/"/>)
        }
    }

    CheckLogin=()=>{
        let Login=SessionHelper.getLogin();
        if(Login!=="YES"){
            this.setState({RedirectWithoutLogin:true})
        }
    }

    componentDidMount() {
        window.scroll(0,0)
        this.CheckLogin();
    }


    render() {
        return (
            <Fragment>
                <TopNavigation/>
                <div className="mobile nav-mobile-top">
                    <TopNavigationMobile/>
                </div>

                <OrderHistoryDetails invoice={this.state.invoice}/>

                <div className="desktop">
                    <Footer/>
                </div>
                <div className="mobile">
                    <FooterMobile/>
                </div>
                {this.pageRedirectWithoutLogin()}
            </Fragment>
        );
    }
}

export default OrderHistoryDetailsPage;