// Reviewed
import React, {Component,Fragment} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link} from "react-router-dom";
import axios from "axios";
import {ProductListSliderURL} from "../../APIServices/ProductListRequest";
import TopBannerSliderPlaceholderMobile from "./TopBannerSliderPlaceholderMobile";
class TopBannerSliderMobile extends Component {

    constructor() {
        super();
        this.state={
            DataList:null
        }
    }
    componentDidMount() {
        this.SelectProductList()
    }
    SelectProductList=()=>{
        axios.get(ProductListSliderURL()).then((res)=>{
            this.setState({DataList:res.data})
        })
    }

    render() {
        let settings = {
            autoplay:true,
            autoplaySpeed:3000,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        let ProductList=this.state.DataList;
        let ProductListView=<TopBannerSliderPlaceholderMobile/>;
        if(ProductList!==null){

            ProductListView=ProductList.map((list,i)=>{
                return(
                    <div className="top-banner shadow-sm bg-white  container-fluid ">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 p-2 col-sm-12 col-12 text-center">
                                <img className="banner-item-img text-center animated slideInDown" src={list['image']} alt=""/>
                            </div>
                            <div className="col-lg-6  col-md-6 p-2 col-sm-12 col-12 text-center">
                                <div className="animated slideInDown">
                                    <h1 className="banner-title">{list['title']}</h1>
                                    <h4 className="banner-sub-title">{list['sub_title']}</h4>
                                    <Link to={"/ProductDetails/"+list['product_code']} className="btn btn-dark">এখনি অর্ডার করুন</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        return (
            <Fragment>
                <div className="slider-overflow" >
                    <Slider {...settings}>
                        {ProductListView}
                    </Slider>
                </div>
            </Fragment>

        );
    }
}
export default TopBannerSliderMobile;