import React, {Component,Fragment} from 'react';

class BlogComments extends Component {
    render() {
        return (
            <Fragment>
                <div className="container mb-2 justify-content-center">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-md-10 col-sm-12 col-12">
                            <div className="card blogDetailsCommentCard">
                                <h1 className="commentsTitle">কমেন্ট লিখুন</h1>
                                     <div className="row">
                                         <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                             <div className="form-group">
                                                 <input type="text" className="form-control" id="" placeholder="নাম লিখুন"/>
                                             </div>
                                         </div>
                                         <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                             <div className="form-group">
                                                 <input type="text" className="form-control" id="" placeholder="মোবাইল নামার"/>
                                             </div>
                                         </div>
                                         <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                             <div className="form-group">
                                                 <textarea className="form-control" id="exampleFormControlTextarea1" rows="1" placeholder="মতামত লিখুন"></textarea>
                                             </div>
                                         </div>
                                         <a className="btn btn-primary comment-btn pull-right">সাবমিট</a>
                                     </div>



                                        <h1 className="commentsTitle mt-4">সব কমেন্ট</h1>
                                        <div className="">
                                            <div className="img-comment">
                                                <a href="#"><img src="/images/user.svg" alt="" className="user-img"/></a>
                                            </div>
                                            <div className="comment-content">
                                                <span className="comment-name"><a href="">মনিরুল ইসলাম</a></span>
                                                <span className="comment-date"><i className="fa fa-clock-o"></i>May 27, 2015</span>
                                                <p className="comment-des text-justify">উদ্যোক্তা হাসান তানভীর বলেন, অ্যাপটি যেকোনো স্মার্টফোনে ব্যবহার করা যাবে। গুগল প্লে স্টোর থেকে বিনা মূল্যে ‘রাজশাহীর আম’ নামের অ্যাপটি ডাউনলোড করা যাবে। এরপর নিজের তথ্য দিয়ে নিবন্ধন করতে হবে। </p>
                                            </div>
                                        </div>

                                        <div className="">
                                            <div className="img-comment">
                                                <a href=""><img src="/images/user.svg" alt="" className="user-img"/></a>
                                            </div>
                                            <div className="comment-content">
                                                <span className="comment-name"><a href="#">মনিরুল ইসলাম</a></span>
                                                <span className="comment-date"><i className="fa fa-clock-o"></i>May 27, 2015</span>
                                                <p className="comment-des text-justify">উদ্যোক্তা হাসান তানভীর বলেন, অ্যাপটি যেকোনো স্মার্টফোনে ব্যবহার করা যাবে। গুগল প্লে স্টোর থেকে বিনা মূল্যে ‘রাজশাহীর আম’ নামের অ্যাপটি ডাউনলোড করা যাবে। এরপর নিজের তথ্য দিয়ে নিবন্ধন করতে হবে। </p>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="img-comment">
                                                <a href=""><img src="/images/user.svg" alt="" className="user-img"/></a>
                                            </div>
                                            <div className="comment-content">
                                                <span className="comment-name"><a href="#">মনিরুল ইসলাম</a></span>
                                                <span className="comment-date"><i className="fa fa-clock-o"></i>May 27, 2015</span>
                                                <p className="comment-des text-justify">উদ্যোক্তা হাসান তানভীর বলেন, অ্যাপটি যেকোনো স্মার্টফোনে ব্যবহার করা যাবে। গুগল প্লে স্টোর থেকে বিনা মূল্যে ‘রাজশাহীর আম’ নামের অ্যাপটি ডাউনলোড করা যাবে। এরপর নিজের তথ্য দিয়ে নিবন্ধন করতে হবে। </p>
                                            </div>
                                        </div>
                            </div>
                        </div>
                    </div>
                    </div>

            </Fragment>
        );
    }
}

export default BlogComments;