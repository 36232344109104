import localForage from "localforage";
import * as localforage from "localforage";
class LocalForgeHelper {

    StoreCategories(JSONValue){
        localForage.setItem("Categories",JSON.stringify(JSONValue))
    }
    RetrieveCategories(callback){
        localforage.getItem('Categories', function (err, value) {
            if(err){
                console.log('LocalForg '+err)
                callback(null) ;
            }
            else {
                callback(JSON.parse(value)) ;
            }
        });
    }


    StoreFooter(JSONValue){
        localForage.setItem("Footer",JSON.stringify(JSONValue))
    }
    RetrieveFooter(callback){
        localforage.getItem('Footer', function (err, value) {
            if(err){
                callback(null) ;
            }
            else {
                callback(JSON.parse(value)) ;
            }
        });
    }





}
export const {
    StoreCategories,
    RetrieveCategories,
    StoreFooter,
    RetrieveFooter

}=new LocalForgeHelper()