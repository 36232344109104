import React, {Component,Fragment} from 'react';
import {Redirect} from "react-router";
import FullScreenLoader from "../Common/FullScreenLoader";
import SessionHelper from "../../Helper/SessionHelper";
import {loginSuccess, otpRequired, requestFail} from "../../Helper/ToastMassageHelper";
import axios from "axios";
import {OTPVerification} from "../../APIServices/OTPRequest";

class Otp extends Component {
    constructor() {
        super();
        this.state={
            isLoading:"d-none",
            Redirect:false,
            FirstDigit:"",
            SecondDigit:"",
            ThirdDigit:"",
            ForthDigit:"",
            FifthDigit:"",
            SixthDigit:"",
        }
    }

    pageRedirect=()=>{
        if(this.state.Redirect===true){
            return(
                <Redirect to={SessionHelper.getProductLocation()}/>
            )
        }
    }

    FirstDigitOnChange=(event)=>{
        if((event.target.value).length===1){
            this.SecondInput.focus();
            this.setState({FirstDigit:event.target.value})
        }
    }
    SecondDigitOnChange=(event)=>{
        if((event.target.value).length===1) {
            this.ThirdInput.focus();
            this.setState({SecondDigit: event.target.value})
        }
        else {
            this.FirstInput.focus();
        }
    }
    ThirdDigitOnChange=(event)=>{
        if((event.target.value).length===1) {
            this.ForthInput.focus();
            this.setState({ThirdDigit:event.target.value})
        }
        else {
            this.SecondInput.focus();
        }

    }
    ForthDigitOnChange=(event)=>{
        if((event.target.value).length===1) {
            this.FifthInput.focus();
            this.setState({ForthDigit: event.target.value})
        }
        else {
            this.ThirdInput.focus();
        }
    }
    FifthDigitOnChange=(event)=>{
        if((event.target.value).length===1) {
            this.setState({FifthDigit: event.target.value})
            this.SixthInput.focus();
        }
        else {
            this.ForthInput.focus();
        }
    }
    SixthDigitOnChange=(event)=>{
        if((event.target.value).length===1) {
            this.setState({SixthDigit: event.target.value})
        }
        else {
            this.FifthInput.focus();
        }
    }

    onLogin=()=>{
            let FirstDigit=this.state.FirstDigit;
            let SecondDigit=this.state.SecondDigit;
            let ThirdDigit=this.state.ThirdDigit;
            let ForthDigit=this.state.ForthDigit;
            let FifthDigit=this.state.FifthDigit;
            let SixthDigit=this.state.SixthDigit;
            let OTP=FirstDigit+SecondDigit+ThirdDigit+ForthDigit+FifthDigit+SixthDigit;

            if(OTP.length!==6){
                otpRequired();
            }
            else{
                this.setState({isLoading:""})
                axios.get(OTPVerification(SessionHelper.getMobile(),OTP))
                    .then((res)=>{
                        this.setState({isLoading:"d-none"})
                        if(res.status===200 && res.data===1){
                            loginSuccess();
                            SessionHelper.setLogin()
                            this.setState({Redirect:true})
                        }
                        else {
                            requestFail();
                        }
                    })
                    .catch((err)=>{
                        this.setState({isLoading:"d-none"})
                        requestFail();
                    })
            }
    }

    render() {
        return (
            <Fragment>
                <div className="container ">
                    <div className="row d-flex animated zoomIn justify-content-center">
                        <div className="col-md-10 my-4 col-lg-10 col-12 col-sm-12">
                            <div className="container shadow-sm p-2 bg-white">
                                <div className="row d-flex justify-content-center">
                                    <div className="col-md-6 col-lg-6 col-sm-12 col-12  ">
                                        <br/>
                                        <div className="login-form">
                                            <h4 className="login-title">ভেরিফিকেশন কোড</h4>
                                            <p className="login-subtitle">{SessionHelper.getOTPNote()}</p>
                                            <div className="input-group mt-2">
                                                <input ref={(input) => {this.FirstInput = input;}} onChange={this.FirstDigitOnChange} maxLength="1" placeholder="X" className="form-control form-control-box text-center m-1" type="tel"/>
                                                <input ref={(input) => {this.SecondInput = input;}} onChange={this.SecondDigitOnChange} maxLength="1" placeholder="X" className="form-control form-control-box text-center m-1" type="tel"/>
                                                <input ref={(input) => {this.ThirdInput = input;}} onChange={this.ThirdDigitOnChange} maxLength="1" placeholder="X" className="form-control form-control-box text-center m-1" type="tel"/>
                                                <input ref={(input) => {this.ForthInput = input;}} onChange={this.ForthDigitOnChange} maxLength="1" placeholder="X" className="form-control form-control-box text-center m-1" type="tel"/>
                                                <input ref={(input) => {this.FifthInput = input;}} onChange={this.FifthDigitOnChange} maxLength="1" placeholder="X" className="form-control form-control-box text-center m-1" type="tel"/>
                                                <input ref={(input) => {this.SixthInput = input;}} onChange={this.SixthDigitOnChange} maxLength="1" placeholder="X" className="form-control form-control-box text-center m-1" type="tel"/>
                                            </div>
                                            <button onClick={this.onLogin} className="btn btn-dark mt-3 btn-block">সপন্ন করুন</button>
                                        </div>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.pageRedirect()}
                <FullScreenLoader isLoading={this.state.isLoading}/>
                <br/><br/><br/><br/><br/><br/><br/>
            </Fragment>
        );
    }
}

export default Otp;