class SessionHelper{

    static setOTPNote(OTPNote){
        return sessionStorage.setItem('OTPNote',OTPNote)
    }
    static  getOTPNote(){
        return sessionStorage.getItem('OTPNote')
    }

    static setMobile(Mobile){
        return localStorage.setItem('Mobile',Mobile)
    }
    static  getMobile(){
        return localStorage.getItem('Mobile')
    }

    static setLogin(){
        return localStorage.setItem('Login',"YES")
    }
    static getLogin(){
        return localStorage.getItem('Login')
    }

    static setEmail(Email){
        return localStorage.setItem('Email',Email)
    }
    static  getEmail(){
        return localStorage.getItem('Email')
    }

    static setProductLocation(ProductLocation){
        return sessionStorage.setItem('ProductLocation',ProductLocation)
    }
    static  getProductLocation(){
        return sessionStorage.getItem('ProductLocation')
    }

    static ClearAllSession(){
        sessionStorage.clear();
        localStorage.clear();
    }

    //checkout sessions

    static setCity(city){
         sessionStorage.setItem('city',city)
    }
    static getCity(){
        return sessionStorage.getItem('city')
    }

    static setLocation(location){
        sessionStorage.setItem('location',location)
    }
    static getLocation(){
        return sessionStorage.getItem('location')
    }


    static setShippingPoint(ShippingPoint){
        sessionStorage.setItem('ShippingPoint',ShippingPoint)
    }
    static getShippingPoint(){
        return sessionStorage.getItem('ShippingPoint')
    }

    static setReceiverName(ReceiverName){
        sessionStorage.setItem('ReceiverName',ReceiverName)
    }
    static getReceiverName(){
        return sessionStorage.getItem('ReceiverName')
    }

    static setReceiverMobile(ReceiverMobile){
        sessionStorage.setItem('ReceiverMobile',ReceiverMobile)
    }
    static getReceiverMobile(){
        return sessionStorage.getItem('ReceiverMobile')
    }

    static setReceiverAddress(ReceiverAddress){
        sessionStorage.setItem('ReceiverAddress',ReceiverAddress)
    }
    static getReceiverAddress(){
        return sessionStorage.getItem('ReceiverAddress')
    }

    static setProductOrderRequestInput(ProductOrderRequestInput){
        sessionStorage.setItem('ProductOrderRequestInput',JSON.stringify(ProductOrderRequestInput))
    }

    static getProductOrderRequestInput(){
        let Data= sessionStorage.getItem('ProductOrderRequestInput');
        return JSON.parse(Data);
    }



    static clearProductOrderRequestInput(){
        sessionStorage.removeItem('ProductOrderRequestInput');
    }

}
export default SessionHelper;