// Completed By Rabbil Hasan
// 14-05-2014 5:43AM
import React, {Component,Fragment} from 'react';
import TopNavigation from "../../Components/Common/TopNavigation";
import Notification from "../../Components/Notification/Notification";
import Footer from "../../Components/Common/Footer";
import TopNavigationMobile from "../../Components/Common/TopNavigationMobile";
import FooterMobile from "../../Components/Common/FooterMobile";
class NotificationListPage extends Component {
    componentDidMount() {
        window.scroll(0,0)
    }
    render() {
        return (
            <Fragment>
                <TopNavigation/>
                <div className="mobile nav-mobile-top">
                    <TopNavigationMobile/>
                </div>
                <Notification/>
                <div className="desktop">
                    <Footer/>
                </div>
                <div className="mobile">
                    <FooterMobile/>
                </div>
            </Fragment>
        );
    }
}
export default NotificationListPage;
