// reviewed
import React, {Component,Fragment} from 'react';
import {Link, NavLink} from "react-router-dom";
import axios from "axios";
import {ProductListRandomURL} from "../../APIServices/ProductListRequest";
import ProductListPlaceholder from "../ProductList/ProductListPlaceholder";
import {AiFillStar, AiOutlineStar} from "react-icons/ai";
import {IoBagHandleSharp} from "react-icons/io5";
class SimilarProductList extends Component {

    constructor() {
        super();
        this.state={
            DataList:null,
        }
    }

    componentDidMount() {
        this.SelectProduct();
    }

    SelectProduct=()=>{
        axios.get(ProductListRandomURL(this.props.code))
            .then((res)=>{
                this.setState({DataList:res.data})
            })
    }

    render() {

        let ProductList=this.state.DataList;
        let ProductListView=<ProductListPlaceholder/>;
        let OneStarView=<span>
            <AiFillStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
        </span>
        let TwoStarView=<span>
            <AiFillStar className="text-success"/>
             <AiFillStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
        </span>
        let ThreeStarView=<span>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
        </span>
        let FourStarView=<span>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
        </span>
        let FiveStarView=<span>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
        </span>
        if(ProductList!==null){
            ProductListView=ProductList.map((list,i)=>{

                // Product Star Conditions
                let StarView;
                if(list['star']==="1"){
                    StarView=OneStarView;
                }
                else if(list['star']==="2") {
                    StarView=TwoStarView;
                }
                else if(list['star']==="3") {
                    StarView=ThreeStarView;
                }
                else if(list['star']==="4") {
                    StarView=FourStarView;
                }
                else if(list['star']==="5") {
                    StarView=FiveStarView;
                }

                // Product Stock YES NO
                // Discount View Percentage
                let StockView=<span></span>;
                if(list['stock']==="NO"){
                    StockView=<span className="top-left badge red">স্টক আউট</span>;
                }
                else if(list['stock']==="CS"){
                    StockView=<span className="top-left badge blue">শীঘ্রই আসছে</span>;
                }
                else if(list['stock']==="YES" && list['discount_type']==="Percentage" ){
                    StockView=<span className="top-left badge green">{list['discount_percentage']} % অফ</span>
                }
                else if(list['stock']==="YES" && list['discount_type']==="Flat" ){
                    StockView=<span className="top-left badge green">{list['flat_discount']} টাকা ডিস্কাউন্ট</span>
                }
                else if(list['stock']==="YES" && list['discount_type']==="NA" ){
                    StockView=<span></span>;
                }

                return(
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 p-1">
                        <div className="card animated zoomIn h-100 w-100 text-center">
                            <div className="badge-overlay">
                                {StockView}
                            </div>
                            <NavLink exact to={"/ProductDetails/"+list['product_code']}>
                            <div className="card-body card-body-custom image-box">
                                <img className="w-100" src={list['image']} />
                                <h5 className="card-title m-0">{list['title']}</h5>
                                {StarView}
                                <h6 className="card-price-title m-0"> <span className="h4 text-danger">৳ </span> {list['unit_price']} টাকা/কেজি</h6>
                            </div>
                            </NavLink>
                        </div>
                    </div>
                )
            })
        }
        return (
            <Fragment>
                <div className="container-fluid mt-2 mt-4 mb-4">
                    <h4 className="section-title text-center">- আরো দেখুন - </h4>
                    <div className="row text-center">
                        {ProductListView}
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default SimilarProductList;