class ContactRequest{
    ContactRequestURL(){
        return process.env.REACT_APP_BASEURL+"ContactRequest"
    }
    ContactRequestBody(name,mobile,message){
        return{
            name:name,
            mobile:mobile,
            message:message
        }
    }
}

export const {ContactRequestURL,ContactRequestBody}=new ContactRequest();