import SessionHelper from "../Helper/SessionHelper";

class ProductOrderRequest{

    ProductOrderTemp(){
        return process.env.REACT_APP_BASEURL+"OrderTemp";
    }
    ProductOrderTempBody(cupon_name,cupon_amount,invoice_no,user_mobile,total_product_weight,total_product_price,total_packing_charge,delivery_charge,total_payment,payment_status,place,area,shipping_point,receiver_name,receiver_mobile,address){
       let item={
           "invoice_no":invoice_no,
           "user_mobile":user_mobile,
           "total_product_weight":total_product_weight,
           "total_product_price":total_product_price,
           "total_packing_charge":total_packing_charge,
           "delivery_charge":delivery_charge,
           "total_payment":total_payment,
           "payment_status":payment_status,
           "place":place,
           "area":area,
           "shipping_point":shipping_point,
           "receiver_name":receiver_name,
           "receiver_mobile":receiver_mobile,
           "address":address,
           "cupon_name": cupon_name,
           "cupon_amount":cupon_amount
       }
       SessionHelper.setProductOrderRequestInput(item);
       return item;
    }
    ProductOrderURL(){
        return process.env.REACT_APP_BASEURL+"OrderPlace";
    }
    ProductOrderBody(){
        let item=SessionHelper.getProductOrderRequestInput();
        return item;
    }
}
export const {ProductOrderURL,ProductOrderBody,ProductOrderTemp,ProductOrderTempBody}=new ProductOrderRequest();