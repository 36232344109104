import React, {Component, Fragment} from 'react';
import {Redirect} from "react-router";
import SessionHelper from "../../Helper/SessionHelper";
import TopNavigation from "../../Components/Common/TopNavigation";
import TopNavigationMobile from "../../Components/Common/TopNavigationMobile";
import Footer from "../../Components/Common/Footer";
import FooterMobile from "../../Components/Common/FooterMobile";
import OrderHistory from "../../Components/OrderHistory/OrderHistory"

class OrderHistoryPage extends Component {


    constructor() {
        super();
        this.state={
            RedirectWithoutLogin:false,
        }
    }
    pageRedirectWithoutLogin=()=>{
        if(this.state.RedirectWithoutLogin===true){
            return(<Redirect to="/"/>)
        }
    }
    CheckLogin=()=>{
        let Login=SessionHelper.getLogin();
        if(Login!=="YES"){
            this.setState({RedirectWithoutLogin:true})
        }
    }
    componentDidMount() {
        window.scroll(0,0)
        this.CheckLogin();
    }


    render() {
        return (
            <Fragment>
                <TopNavigation/>
                <div className="mobile nav-mobile-top">
                    <TopNavigationMobile/>
                </div>

                <OrderHistory/>


                <div className="desktop">
                    <Footer/>
                </div>
                <div className="mobile">
                    <FooterMobile/>
                </div>
                {this.pageRedirectWithoutLogin()}
            </Fragment>
        );
    }
}

export default OrderHistoryPage;