import React, {Component, Fragment} from 'react';
import nodata from './../../Assets/Img/emptycart.svg';
class EmptyCartList extends Component {
    render() {
        return (
            <Fragment>
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-3 text-center nocart-div col-md-3 col-sm-6 col-6">
                            <img alt="nodata" className="w-100" src={nodata}/>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default EmptyCartList;