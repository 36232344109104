//Reviewed
import React, {Component,Fragment} from 'react';
import axios from "axios";
import ProductListPlaceholder from "./ProductListPlaceholder";
import {ProductListCategoryURL} from "../../APIServices/ProductListRequest";
import {AiFillStar, AiOutlineStar} from "react-icons/ai";
import {IoBagHandleSharp} from "react-icons/io5";
import {Link, NavLink} from "react-router-dom";
import NoDataFound from "../Common/NoDataFound";
class ProductList extends Component {
    constructor() {
        super();
        this.state={
            DataList:null,
            nodata:false,
        }
    }
    componentDidMount() {
        this.SelectProductList(this.props.code)
    }

    SelectProductList=(code)=>{
        axios.get(ProductListCategoryURL(code)).then((res)=>{
            if(res.status===200 && res.data.length>0){
                this.setState({DataList:res.data})
            }
            else if(res.data.length===0){
                this.setState({nodata:true})
            }
            else{
                this.setState({nodata:true})
            }
        }).catch((err)=>{
            this.setState({nodata:true})
        })
    }

    render() {
        let ProductList=this.state.DataList;
        let ProductListView=<ProductListPlaceholder/>;
        let OneStarView=<span>
            <AiFillStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
        </span>
        let TwoStarView=<span>
            <AiFillStar className="text-success"/>
             <AiFillStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
        </span>
        let ThreeStarView=<span>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
        </span>
        let FourStarView=<span>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
        </span>
        let FiveStarView=<span>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
        </span>

        if(ProductList!==null){
            ProductListView=ProductList.map((list,i)=>{

                // Product Star Conditions
                let StarView;
                if(list['star']==="1"){
                    StarView=OneStarView;
                }
                else if(list['star']==="2") {
                    StarView=TwoStarView;
                }
                else if(list['star']==="3") {
                   StarView=ThreeStarView;
                }
                else if(list['star']==="4") {
                    StarView=FourStarView;
                }
                else if(list['star']==="5") {
                    StarView=FiveStarView;
                }

                // Product Stock YES NO
                // Discount View Percentage
                let StockView=<span></span>;
                if(list['stock']==="NO"){
                    StockView=<span className="top-left badge red">স্টক আউট</span>;
                }
                else if(list['stock']==="CS"){
                    StockView=<span className="top-left badge blue">শীঘ্রই আসছে</span>;
                }
                else if(list['stock']==="YES" && list['discount_type']==="Percentage" ){
                    StockView=<span className="top-left badge green">{list['discount_percentage']} % অফ</span>
                }
                else if(list['stock']==="YES" && list['discount_type']==="Flat" ){
                    StockView=<span className="top-left badge green">{list['flat_discount']} টাকা ডিস্কাউন্ট</span>
                }
                else if(list['stock']==="YES" && list['discount_type']==="NA" ){
                    StockView=<span></span>;
                }

                let unit_price=list['unit_price'];
                let product_price=list['product_price'];
                let unit=list['unit'];
                // Price View
                let PriceView=<h6 className="card-price-title m-0"> <span className="h4 text-danger">৳ </span> <strike>{unit_price}</strike> {list['product_price']} টাকা/{unit}</h6>

                if(product_price===null){
                    PriceView=<h6 className="card-price-title m-0"> <span className="h4 text-danger">৳ </span> {unit_price} টাকা/{unit}</h6>
                }


                return(
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 p-1">
                        <div className="card animated fadeIn h-100 w-100 text-center">
                            <div className="badge-overlay">
                                {StockView}
                            </div>
                            <NavLink exact to={"/ProductDetails/"+list['product_code']}>
                            <div className="card-body card-body-custom image-box">
                                <img className="w-100" src={list['image']} />
                                <h5 className="card-title m-0">{list['title']}</h5>
                                {StarView}
                                {PriceView}
                            </div>
                            </NavLink>
                        </div>
                    </div>
                )
            })
        }
        else if(this.state.nodata===true){
            ProductListView=<NoDataFound/>
        }
        return (
            <Fragment>
                <div className="container-fluid mt-4 mb-4">
                    <div className="row">
                        {ProductListView}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default ProductList;