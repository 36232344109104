import React, {Component,Fragment} from 'react';
import TopNavigation from "../../Components/Common/TopNavigation";
import TopNavigationMobile from "../../Components/Common/TopNavigationMobile";
import Footer from "../../Components/Common/Footer";
import FooterMobile from "../../Components/Common/FooterMobile";
import PaymentFail from "../../Components/PaymentFail/PaymentFail";
import {Redirect} from "react-router";
import SessionHelper from "../../Helper/SessionHelper";

class PaymentFailPage extends Component {

    constructor({match}) {
        super();
        this.state={
            trx:match.params.trx,
            RedirectWithoutLogin:false,
        }
    }

    componentDidMount() {
        window.scroll(0,0)
        this.CheckLogin();
    }

    pageRedirectWithoutLogin=()=>{
        if(this.state.RedirectWithoutLogin===true){
            return(<Redirect to="/"/>)
        }
    }
    CheckLogin=()=>{
        let Login=SessionHelper.getLogin();
        if(Login!=="YES"){
            this.setState({RedirectWithoutLogin:true})
        }
    }


    render() {
        return (
            <Fragment>
                <TopNavigation/>
                <div className="mobile nav-mobile-top">
                    <TopNavigationMobile/>
                </div>



                <PaymentFail/>


                <div className="desktop">
                    <div className="desktop">
                        <Footer/>
                    </div>
                    <div className="mobile">
                        <FooterMobile/>
                    </div>

                </div>
                <div className="mobile">
                    <FooterMobile/>
                </div>
                {this.pageRedirectWithoutLogin()}
            </Fragment>
        );
    }
}

export default PaymentFailPage;